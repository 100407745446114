//site colors
$burger-background: #274293;
$profile-background: #1A30A5;
$profile-background-pressed: #0228AF;
$footer-background: #020E32;
$dark-blue: #274BB9;
$light-gray-blue: #D9E2F5;

//text
$blue-text-color: #274293;
$dark-blue-text-color: #041C55;

// Trainer colors
$trainer-accent-blue: #17348B;
$trainer-default-bg: #EEF3FD;
$trainer-accent-green: #23E964;

// buttons
$orange-color-base: #FF7F45;
$orange-color-accent: #FF471E;
$orange-color-hover: #FF3D0D;
$orange-color-hover-second: #FF4B1F;
$orange-color-disabled: #CED2E1;
$orange-gradient: linear-gradient(to bottom, $orange-color-base 0%, $orange-color-accent 100%);
$orange-gradient-hover: linear-gradient(to bottom, $orange-color-hover 0%, $orange-color-hover-second 100%);

$restart-color-base: #A9B3D4;
$restart-color-hover: #8591B9;

$to-mission-color: #D1D8EC;

// skills-colors
$imagination-color: #8C62C1;
$memory-color: #5BBF4A;
$focus-color: #E24067;
$mind-color: #4C8EDB;
$logic-color: #F39C19;

// skills-filter
$skills-filter-base: rgba(#7A92D8, .25);
$skills-filter-pressed: rgba(#7A92D8, .60);

// media queries vars
$mq-breakpoints: (
  mobile-sm: 320px,
  mobile: 320px,
  tablet: 768px,
  desktop: 1025px,
  wide: 1400px,
  desktopAd: 810px,
  mobileLandscape: 480px
);

//font
$ptsans: 'PTSans', sans-serif;

// shadows
$shadow: 0 5px 12px rgba(67, 101, 153, .11);
$inner-shadow: inset 0 5px 8px rgba(0, 28, 69, .25);

@import '~sass-mq/_mq.scss';

@mixin font-face($name, $filename, $path, $weight:normal, $style:normal) {
  @font-face {
    font-family: $name;
    src: url($path + $filename + '.woff2') format('woff2'),
      url($path + $filename + '.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin edit-cell-sudoku($borderColor, $color, $background) {
  border: 2px solid $borderColor;
  position: relative;
  color: $color;
  width: 59px;
  height: 59px;
  cursor: auto;

  &:before {
    content: '';
    position: absolute;
    background: $background;
    width: 100%;
    height: 100%;
  }
}

.mission-status {
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  color: white;
  border-radius: 10px;

  @include mq($from: mobile-sm, $until: tablet) {
    display: flex;
    max-width: 400px;
    margin: 0 auto;
    width: 100%;
  }

  &__label {
    font-size: 18px;
    line-height: .66;
    margin-right: 20px;
    white-space: nowrap;

    @include mq($from: mobile-sm, $until: tablet) {
      width: 100%;
      font-size: 16px;
    }
  }

  &__segments {
    display: flex;
  }

  &__segment {
    width: 10px;
    height: 18px;
    margin-right: 3px;
    margin-left: 2px;
    background: #6B82C8;
    border-radius: 10px;
    box-sizing: border-box;

    @include mq($from: tablet) {
      height: 23px;
    }

    &:last-child {
      margin-right: 0;
    }

    &:first-child {
      margin-left: 0;
    }

    &_complete {
      background: linear-gradient(to bottom, rgba(77,255,137,1) 0%,rgba(0,212,59,1) 100%);
      box-shadow: 0 0 10px 0 rgba(0, 220, 49, 0.7);
    }

    &_partially {
      background: linear-gradient(to bottom, rgba(255,248,77,1) 0%,rgba(241,159,0,1) 100%);
      box-shadow: 0 0 10px 0 rgba(220, 198, 0, 0.7);
    }

    &_fail {
      background: linear-gradient(to bottom, rgba(255,100,110,1) 0%,rgba(224,31,54,1) 100%);
      box-shadow: 0 0 10px 0 rgba(244, 51, 64, 1);
    }

    &_current {
      border: 2px solid #FFFFFF;
    }
  }
}
