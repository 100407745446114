//site colors
$burger-background: #274293;
$profile-background: #1A30A5;
$profile-background-pressed: #0228AF;
$footer-background: #020E32;
$dark-blue: #274BB9;
$light-gray-blue: #D9E2F5;

//text
$blue-text-color: #274293;
$dark-blue-text-color: #041C55;

// Trainer colors
$trainer-accent-blue: #17348B;
$trainer-default-bg: #EEF3FD;
$trainer-accent-green: #23E964;

// buttons
$orange-color-base: #FF7F45;
$orange-color-accent: #FF471E;
$orange-color-hover: #FF3D0D;
$orange-color-hover-second: #FF4B1F;
$orange-color-disabled: #CED2E1;
$orange-gradient: linear-gradient(to bottom, $orange-color-base 0%, $orange-color-accent 100%);
$orange-gradient-hover: linear-gradient(to bottom, $orange-color-hover 0%, $orange-color-hover-second 100%);

$restart-color-base: #A9B3D4;
$restart-color-hover: #8591B9;

$to-mission-color: #D1D8EC;

// skills-colors
$imagination-color: #8C62C1;
$memory-color: #5BBF4A;
$focus-color: #E24067;
$mind-color: #4C8EDB;
$logic-color: #F39C19;

// skills-filter
$skills-filter-base: rgba(#7A92D8, .25);
$skills-filter-pressed: rgba(#7A92D8, .60);

// media queries vars
$mq-breakpoints: (
  mobile-sm: 320px,
  mobile: 320px,
  tablet: 768px,
  desktop: 1025px,
  wide: 1400px,
  desktopAd: 810px,
  mobileLandscape: 480px
);

//font
$ptsans: 'PTSans', sans-serif;

// shadows
$shadow: 0 5px 12px rgba(67, 101, 153, .11);
$inner-shadow: inset 0 5px 8px rgba(0, 28, 69, .25);

@import '~sass-mq/_mq.scss';

@mixin font-face($name, $filename, $path, $weight:normal, $style:normal) {
  @font-face {
    font-family: $name;
    src: url($path + $filename + '.woff2') format('woff2'),
      url($path + $filename + '.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin edit-cell-sudoku($borderColor, $color, $background) {
  border: 2px solid $borderColor;
  position: relative;
  color: $color;
  width: 59px;
  height: 59px;
  cursor: auto;

  &:before {
    content: '';
    position: absolute;
    background: $background;
    width: 100%;
    height: 100%;
  }
}

.button {
  background: none;
  border: none;
  padding: 0;
  display: block;
  width: 100%;
  cursor: pointer;
  position: relative;

  &_round {
    height: 55px;
  }

  &_theme-orange {
    text-align: center;
    font-family: $ptsans;
    font-weight: 700;
    font-size: 14px;
    line-height: 50px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-decoration: none;
    background: linear-gradient(180deg, $orange-color-base 0%, $orange-color-accent 100%);
    box-shadow: 0px 8px 20px rgba(238, 74, 0, 0.5);
    border-radius: 29px;
    height: 50px;
    display: block;
    outline: 0;

    &:hover,
    &:focus {
      background: linear-gradient(180deg, $orange-color-hover 0%, $orange-color-accent 100%);
    }
  }

  &_theme-grey {
    text-align: center;
    font-family: $ptsans;
    font-weight: 700;
    font-size: 14px;
    line-height: 50px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #041C55;
    text-decoration: none;
    background: #8B99C5;
    border-radius: 30px;
    height: 50px;
    display: block;
    outline: 0;
    max-width: 218px;
  }

  &_theme-blue {
    text-align: center;
    font-family: $ptsans;
    font-weight: 700;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #041C55;
    text-decoration: none;
    background: #274bb91a;
    border-radius: 29px;
    height: 50px;
    display: block;
    outline: 0;

    &:hover {
      background: rgba(0, 90, 255, 0.72);
    }

    &:active {
      background: #4385FE;
      opacity: 0.9;
    }
  }

  &_theme-light-blue {
    text-align: center;
    font-family: $ptsans;
    font-weight: 700;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    height: 50px;
    display: block;
    outline: 0;
    background: linear-gradient(0, #274BB9, #274BB9);
    box-shadow: 0 3px 10px rgba(0, 24, 111, .64);
    border-radius: 30px;
  }

  &_size-m {
    width: 205px;
  }

  &_size-l {
    width: 235px;
  }

  &_center {
    margin-left: auto;
    margin-right: auto;
  }

  &:disabled {
    background: linear-gradient(180deg, $orange-color-disabled 0%, #ADB5CB 100%);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.13);
    pointer-events: none;
  }

  &_theme-grey-link {
    width: auto;
    color: #A0A6B9;
  }

  &_theme-orange-link {
    width: auto;
    color: $orange-color-accent;
  }

  &_locked {
    background: linear-gradient(180deg, #99A0C2 0%, #4B5D8C 100%);
    pointer-events: none;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.13);
  }

  &_locked-close {
    background: linear-gradient(180deg, #99A0C2 0%, #4B5D8C 100%);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.13);

    &:hover,
    &:focus {
      background: linear-gradient(180deg, #99A0C2 0%, #4B5D8C 100%);
      box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.13);
    }
  }

  &_replay {
    box-shadow: none;
    background: none;
    border: 2px solid $restart-color-hover;
    color: $restart-color-base;
    font-weight: 900;

    &:hover,
    &:focus {
      background: #E9EEFF;
      color: $restart-color-hover;
    }
  }

  &_result {
    box-shadow: none;
    background: #07C5A3;
    border: none;
    color: white;
    font-weight: 900;

    &:hover,
    &:focus {
      background: #07C5A3;
      color: white;
    }
  }

  &_theme-skip-video {
    color: #fff;
    padding: 0;
    border: 0;
    background-color: transparent;
    white-space: nowrap;

    &_center {
      margin-left: auto;
      margin-right: auto;
    }

    color: #fff;
    padding: 0;
    border: 0;
    background-color: transparent;

    .button__icon {
      fill: #fff;
      width: 12px;
      height: 12px;
      margin-right: 14px;
    }
  }

  &_gift-close {
    border: 3px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 3px 10px rgba(0, 53, 239, 0.64);
    border-radius: 30px;
    color: white;
    background: none;
    line-height: 1;

    &:hover,
    &:focus {
      background: none;
    }
  }
}
