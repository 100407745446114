//site colors
$burger-background: #274293;
$profile-background: #1A30A5;
$profile-background-pressed: #0228AF;
$footer-background: #020E32;
$dark-blue: #274BB9;
$light-gray-blue: #D9E2F5;

//text
$blue-text-color: #274293;
$dark-blue-text-color: #041C55;

// Trainer colors
$trainer-accent-blue: #17348B;
$trainer-default-bg: #EEF3FD;
$trainer-accent-green: #23E964;

// buttons
$orange-color-base: #FF7F45;
$orange-color-accent: #FF471E;
$orange-color-hover: #FF3D0D;
$orange-color-hover-second: #FF4B1F;
$orange-color-disabled: #CED2E1;
$orange-gradient: linear-gradient(to bottom, $orange-color-base 0%, $orange-color-accent 100%);
$orange-gradient-hover: linear-gradient(to bottom, $orange-color-hover 0%, $orange-color-hover-second 100%);

$restart-color-base: #A9B3D4;
$restart-color-hover: #8591B9;

$to-mission-color: #D1D8EC;

// skills-colors
$imagination-color: #8C62C1;
$memory-color: #5BBF4A;
$focus-color: #E24067;
$mind-color: #4C8EDB;
$logic-color: #F39C19;

// skills-filter
$skills-filter-base: rgba(#7A92D8, .25);
$skills-filter-pressed: rgba(#7A92D8, .60);

// media queries vars
$mq-breakpoints: (
  mobile-sm: 320px,
  mobile: 320px,
  tablet: 768px,
  desktop: 1025px,
  wide: 1400px,
  desktopAd: 810px,
  mobileLandscape: 480px
);

//font
$ptsans: 'PTSans', sans-serif;

// shadows
$shadow: 0 5px 12px rgba(67, 101, 153, .11);
$inner-shadow: inset 0 5px 8px rgba(0, 28, 69, .25);

@import '~sass-mq/_mq.scss';

@mixin font-face($name, $filename, $path, $weight:normal, $style:normal) {
  @font-face {
    font-family: $name;
    src: url($path + $filename + '.woff2') format('woff2'),
      url($path + $filename + '.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin edit-cell-sudoku($borderColor, $color, $background) {
  border: 2px solid $borderColor;
  position: relative;
  color: $color;
  width: 59px;
  height: 59px;
  cursor: auto;

  &:before {
    content: '';
    position: absolute;
    background: $background;
    width: 100%;
    height: 100%;
  }
}

.trainer-card {
  font-family: $ptsans;
  $root: &;
  z-index: 0;

  @include mq($until: desktopAd) {
    width: 339px;
  }

  &_hovered {
    z-index: 9999;
  }

  &__wrapper {
    border-radius: 10px;
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  &__front {
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 3px solid $trainer-default-bg;
    border-radius: 10px;
    background-color: $trainer-default-bg;
    backface-visibility: hidden;

    #{$root}_hovered & {
      border: 3px solid #FF8045;
      box-shadow: 0px 8px 20px rgba(235, 128, 68, 0.69);

      @for $i from 1 through 3 {
        &.properties-length-#{$i} {
          height: #{($i - 1) * 51 + 320}px;

          @include mq($until: mobileLandscape) {
            height: #{($i - 1) * 51 + 241}px;
          }
        }
      }

      @include mq($until: mobileLandscape) {
        height: 281px;
      }
    }

    #{$root}_train#{$root}_mobile &,
    #{$root}_train#{$root}_hovered & {
      @for $i from 1 through 3 {
        &.properties-length-#{$i} {
          height: #{($i - 1) * 51 + 241}px;
        }
      }
    }

    // @include mq($until: mobileLandscape) {
    //   height: 241px;
    // }
  }

  &__back {
    background-color: $trainer-default-bg;
    border-radius: 10px;
    padding: 26px 26px 20px;
    position: absolute;
    box-sizing: border-box;
    width: 100%;
  }
}
