//site colors
$burger-background: #274293;
$profile-background: #1A30A5;
$profile-background-pressed: #0228AF;
$footer-background: #020E32;
$dark-blue: #274BB9;
$light-gray-blue: #D9E2F5;

//text
$blue-text-color: #274293;
$dark-blue-text-color: #041C55;

// Trainer colors
$trainer-accent-blue: #17348B;
$trainer-default-bg: #EEF3FD;
$trainer-accent-green: #23E964;

// buttons
$orange-color-base: #FF7F45;
$orange-color-accent: #FF471E;
$orange-color-hover: #FF3D0D;
$orange-color-hover-second: #FF4B1F;
$orange-color-disabled: #CED2E1;
$orange-gradient: linear-gradient(to bottom, $orange-color-base 0%, $orange-color-accent 100%);
$orange-gradient-hover: linear-gradient(to bottom, $orange-color-hover 0%, $orange-color-hover-second 100%);

$restart-color-base: #A9B3D4;
$restart-color-hover: #8591B9;

$to-mission-color: #D1D8EC;

// skills-colors
$imagination-color: #8C62C1;
$memory-color: #5BBF4A;
$focus-color: #E24067;
$mind-color: #4C8EDB;
$logic-color: #F39C19;

// skills-filter
$skills-filter-base: rgba(#7A92D8, .25);
$skills-filter-pressed: rgba(#7A92D8, .60);

// media queries vars
$mq-breakpoints: (
  mobile-sm: 320px,
  mobile: 320px,
  tablet: 768px,
  desktop: 1025px,
  wide: 1400px,
  desktopAd: 810px,
  mobileLandscape: 480px
);

//font
$ptsans: 'PTSans', sans-serif;

// shadows
$shadow: 0 5px 12px rgba(67, 101, 153, .11);
$inner-shadow: inset 0 5px 8px rgba(0, 28, 69, .25);

@import '~sass-mq/_mq.scss';

@mixin font-face($name, $filename, $path, $weight:normal, $style:normal) {
  @font-face {
    font-family: $name;
    src: url($path + $filename + '.woff2') format('woff2'),
      url($path + $filename + '.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin edit-cell-sudoku($borderColor, $color, $background) {
  border: 2px solid $borderColor;
  position: relative;
  color: $color;
  width: 59px;
  height: 59px;
  cursor: auto;

  &:before {
    content: '';
    position: absolute;
    background: $background;
    width: 100%;
    height: 100%;
  }
}

.profile-skills {
  width: 100%;
  min-height: 391px;
  position: relative;

  &__holder {
    background: rgba(#001D68, .7);
    border-radius: 10px;
    height: 100%;
    padding: 28px 20px;
    box-sizing: border-box;

    @include mq($from: 686px) {
      padding: 30px 54px;
    }
  }

  &__form {
    width: 7px;
    height: 180px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: none;

    @include mq($from: 686px) {
      display: block;
    }

    &_2 {
      right: 0;
      transform: translateY(-50%) rotate(180deg);
    }
  }

  &__form-icon {
    width: 100%;
    height: 100%;
    fill: #19378E;
  }

  &__labels {
    display: flex;
    justify-content: space-between;
  }

  &__label {
    font-family: "PTSans", sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 1.66;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.5);
    opacity: .5;
  }

  &__status {
    font-family: $ptsans;
    font-weight: 700;
    font-size: 24px;
    line-height: 1;
    color: white;
  }

  &__statuses {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;

    @include mq($from: 686px) {
      margin-top: 25px;
    }
  }

  &__next-status {
    color: rgba(255, 255, 255, 0.35);
  }

  &__list {
    margin-top: 22px;
    display: flex;
    overflow: auto;

    @include mq($from: 686px) {
      margin-top: 40px;
    }
  }

  &__desc {
    font-family: $ptsans;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.44;
    color: white;
    margin-top: 35px;

    @include mq($from: 686px) {
      margin-top: 40px;
    }
  }

  &__item {
    padding: 13px 14px;
    background: #010e3c;
    min-width: 94px;
    flex-shrink: 0;

    & + & {
      margin-left: 20px;
    }

    @include mq($from: 429px) {
      padding: 16px 14px 17px;

      & + & {
        margin-left: 14px;
      }
    }

    @include mq($from: tablet) {
      padding: 26px 30px 40px;

      & + & {
        margin-left: 19px;
      }
    }
  }

  &__item-circle {
    stroke: #071540;
  }

  &__item-holder {
    width: 66px;
    height: 66px;

    @include mq($from: 429px) {
      width: 83px;
      height: 83px;
    }

    @include mq($from: desktop) {
      width: 132px;
      height: 132px;
    }
  }

  &__current-holder {
    display: flex;
    align-items: center;
  }
}
