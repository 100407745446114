//site colors
$burger-background: #274293;
$profile-background: #1A30A5;
$profile-background-pressed: #0228AF;
$footer-background: #020E32;
$dark-blue: #274BB9;
$light-gray-blue: #D9E2F5;

//text
$blue-text-color: #274293;
$dark-blue-text-color: #041C55;

// Trainer colors
$trainer-accent-blue: #17348B;
$trainer-default-bg: #EEF3FD;
$trainer-accent-green: #23E964;

// buttons
$orange-color-base: #FF7F45;
$orange-color-accent: #FF471E;
$orange-color-hover: #FF3D0D;
$orange-color-hover-second: #FF4B1F;
$orange-color-disabled: #CED2E1;
$orange-gradient: linear-gradient(to bottom, $orange-color-base 0%, $orange-color-accent 100%);
$orange-gradient-hover: linear-gradient(to bottom, $orange-color-hover 0%, $orange-color-hover-second 100%);

$restart-color-base: #A9B3D4;
$restart-color-hover: #8591B9;

$to-mission-color: #D1D8EC;

// skills-colors
$imagination-color: #8C62C1;
$memory-color: #5BBF4A;
$focus-color: #E24067;
$mind-color: #4C8EDB;
$logic-color: #F39C19;

// skills-filter
$skills-filter-base: rgba(#7A92D8, .25);
$skills-filter-pressed: rgba(#7A92D8, .60);

// media queries vars
$mq-breakpoints: (
  mobile-sm: 320px,
  mobile: 320px,
  tablet: 768px,
  desktop: 1025px,
  wide: 1400px,
  desktopAd: 810px,
  mobileLandscape: 480px
);

//font
$ptsans: 'PTSans', sans-serif;

// shadows
$shadow: 0 5px 12px rgba(67, 101, 153, .11);
$inner-shadow: inset 0 5px 8px rgba(0, 28, 69, .25);

@import '~sass-mq/_mq.scss';

@mixin font-face($name, $filename, $path, $weight:normal, $style:normal) {
  @font-face {
    font-family: $name;
    src: url($path + $filename + '.woff2') format('woff2'),
      url($path + $filename + '.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin edit-cell-sudoku($borderColor, $color, $background) {
  border: 2px solid $borderColor;
  position: relative;
  color: $color;
  width: 59px;
  height: 59px;
  cursor: auto;

  &:before {
    content: '';
    position: absolute;
    background: $background;
    width: 100%;
    height: 100%;
  }
}

.trainer-long-memory {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  box-sizing: border-box;

  @include mq($from: tablet) {
    padding: 40px 30px;
  }

  &__container {
    width: 100%;
    margin: auto;
    max-width: 709px;
  }

  &__task {
    background: #D9E2F5;
    padding: 12px 16px 18px;
    border-radius: 6px;
    margin-bottom: 10px;
    line-height: 18px;

    @include mq($from: tablet) {
      padding: 24px 30px 30px;
      margin-bottom: 40px;
      border-radius: 10px;
      font-size: 18px;
      line-height: 26px;
    }
  }

  &__task-title {
    font-family: $ptsans;
    font-weight: 700;
    line-height: 26px;
    font-size: 18px;
    color: #041C55;
  }

  &__task-desc {
    font-family: $ptsans;
    line-height: 26px;
    font-size: 18px;
    color: #041C55;
  }

  &__options {
    display: flex;
    margin: 0 -5px;
    flex-flow: row wrap;
    position: relative;

    @include mq($from: tablet) {
      margin: 0 -10px;
    }
  }

  &__option-wrapper {
    width: 50%;
    margin-bottom: 10px;
    position: relative;
    border-radius: 12px;
    box-sizing: border-box;
    padding: 0 5px;

    @include mq($from: 500px) {
      width: 33.33%;
    }

    @include mq($from: tablet) {
      width: 25%;
      margin-bottom: 22px;
      padding: 0 10px;
    }

    &_single {
      width: 50%;

      @include mq($from: tablet) {
        width: 33.33%;
      }
    }
  }

  &__option-holder {
    border-radius: 12px;
    height: 96%;
    overflow: hidden;
    box-shadow: 0 5px 12px rgba(0, 38, 96, 0.11);
    background: #fff;

    @include mq($from: 600px) {
      height: 98%;
    }

    @include mq($from: tablet) {
      height: 97%;
    }

    &_selected {
      height: 93%;
      border: 4px solid #00A4EA;
    }

    &_not-selected {
      filter: grayscale(1);
      opacity: 0.6;
      box-shadow: none;
    }

    &_correct {
      height: 93%;
      border: 3px solid #A0A6B9;
      filter: none;

      @include mq($from: 600px) {
        height: 96%;
      }

      @include mq($from: tablet) {
        height: 94%;
      }
    }

    &_selected-correct {
      border: 3px solid #00C159;
    }

    &_selected-incorrect {
      border: 3px solid #FF3509;
    }

    &_not-selected-correct {
      filter: none;
    }
  }

  &__option {
    width: 100%;
    padding-top: 100%;
    cursor: pointer;
    background-size: 100%;
    border-radius: 8px;
    background-repeat: no-repeat;

    &:disabled {
      cursor: not-allowed;
    }
  }

  &__info {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: auto;
    margin-top: 18px;

    @include mq($from: tablet) {
      margin-top: 0;
    }
  }

  &__info-slide-image {
    position: relative;
    z-index: 10;
    width: 142px;
    margin-left: auto;
    margin-right: auto;

    @include mq($from: 365px) {
      width: 242px;
    }
  }

  &__info-slide-img {
    width: 100%;
    height: 100%;
    box-shadow: 0px 8px 15px rgba(0, 38, 96, 0.3);
    border-radius: 10px;

    @include mq($from: 365px) {
      box-shadow: none;
    }
  }

  &__info-slide-block {
    background: none;
    border-radius: 10px;
    padding: 80px 40px 40px;
    margin-top: -70px;

    @include mq($from: 365px) {
      background: white;
    }
  }

  &__info-slide-title {
    font-family: $ptsans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    color: #041C55;
    margin-bottom: 10px;
  }

  &__info-slide-text {
    font-family: $ptsans;
    line-height: 26px;
    font-size: 18px;
    color: #041C55;
  }

  &__slider-nav {
    width: 30px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
    position: absolute;
    margin-top: 50px;
    z-index: 1;

    &_prev {
      left: -10px;
      top: 0;

      @include mq($from: 365px) {
        left: 0;
      }

      @include mq($from: 480px) {
        left: 10%;
      }

      @include mq($from: desktop) {
        left: 0;
        transform: translateX(-200%);
        top: 50%;
      }
    }

    &_next {
      right: -10px;
      top: 0;

      @include mq($from: 365px) {
        right: 0;
      }

      @include mq($from: 480px) {
        right: 10%;
      }

      @include mq($from: desktop) {
        right: 0;
        transform: translateX(200%);
        top: 50%;
      }
    }
  }

  &__slider-nav-icon {
    width: 18px;
    height: 30px;
  }

  &__next-button {
    display: inline-block;
    padding: 0 100px;
    width: auto;
  }

  &__check-answers,
  &__next {
    margin: 8px auto;
    position: fixed;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;
    width: 120px;

    & .button {
      font-size: 12px;
      line-height: 40px;
      height: 40px;
    }

    @include mq($from: tablet) {
      margin: 40px auto 20px;
      width: 235px;

      & .button {
        font-size: 14px;
        line-height: 50px;
        height: 50px;
      }
    }
  }

  &__counter {
    position: fixed;
    bottom: 80px;
    width: 85px;
    height: 90px;
    right: 0;
  }
}
