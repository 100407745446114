//site colors
$burger-background: #274293;
$profile-background: #1A30A5;
$profile-background-pressed: #0228AF;
$footer-background: #020E32;
$dark-blue: #274BB9;
$light-gray-blue: #D9E2F5;

//text
$blue-text-color: #274293;
$dark-blue-text-color: #041C55;

// Trainer colors
$trainer-accent-blue: #17348B;
$trainer-default-bg: #EEF3FD;
$trainer-accent-green: #23E964;

// buttons
$orange-color-base: #FF7F45;
$orange-color-accent: #FF471E;
$orange-color-hover: #FF3D0D;
$orange-color-hover-second: #FF4B1F;
$orange-color-disabled: #CED2E1;
$orange-gradient: linear-gradient(to bottom, $orange-color-base 0%, $orange-color-accent 100%);
$orange-gradient-hover: linear-gradient(to bottom, $orange-color-hover 0%, $orange-color-hover-second 100%);

$restart-color-base: #A9B3D4;
$restart-color-hover: #8591B9;

$to-mission-color: #D1D8EC;

// skills-colors
$imagination-color: #8C62C1;
$memory-color: #5BBF4A;
$focus-color: #E24067;
$mind-color: #4C8EDB;
$logic-color: #F39C19;

// skills-filter
$skills-filter-base: rgba(#7A92D8, .25);
$skills-filter-pressed: rgba(#7A92D8, .60);

// media queries vars
$mq-breakpoints: (
  mobile-sm: 320px,
  mobile: 320px,
  tablet: 768px,
  desktop: 1025px,
  wide: 1400px,
  desktopAd: 810px,
  mobileLandscape: 480px
);

//font
$ptsans: 'PTSans', sans-serif;

// shadows
$shadow: 0 5px 12px rgba(67, 101, 153, .11);
$inner-shadow: inset 0 5px 8px rgba(0, 28, 69, .25);

@import '~sass-mq/_mq.scss';

@mixin font-face($name, $filename, $path, $weight:normal, $style:normal) {
  @font-face {
    font-family: $name;
    src: url($path + $filename + '.woff2') format('woff2'),
      url($path + $filename + '.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin edit-cell-sudoku($borderColor, $color, $background) {
  border: 2px solid $borderColor;
  position: relative;
  color: $color;
  width: 59px;
  height: 59px;
  cursor: auto;

  &:before {
    content: '';
    position: absolute;
    background: $background;
    width: 100%;
    height: 100%;
  }
}

.profile-widget {
  width: 44px;
  height: 44px;
  border-radius: 22px;
  background: linear-gradient(360deg, $profile-background 0%, #0A4AC6 100%);
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 20px;

  @include mq($from: 360px) {
    width: 178px;
    height: 44px;
    border-radius: 22px;
    margin: 0;
  }

  &__link {
    display: flex;
    align-items: center;
    text-decoration: none;
  }

  &__avatar {
    width: 36px;
    height: 36px;
    background-color: #081335;
    border-radius: 50%;
    margin-left: 4px;
  }

  &__info {
    display: none;

    @include mq($from: 360px) {
      display: block;
      margin-left: 10px;
      text-align: left;
    }
  }

  &__name {
    font-family: $ptsans;
    font-weight: 700;
    font-size: 14px;
    color: white;
  }

  &__level {
    text-transform: uppercase;
    font-family: $ptsans;
    font-weight: 700;
    font-size: 12px;
    opacity: 0.65;
    letter-spacing: 1px;
    color: #C4C4C4;

    @supports (background-clip: text) {
      color: transparent;
      background-image: linear-gradient(90deg, #C4C4C4 80%, rgba(196, 196, 196, 0) 99.8%);
      background-clip: text;
    }
  }
}
