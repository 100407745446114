//site colors
$burger-background: #274293;
$profile-background: #1A30A5;
$profile-background-pressed: #0228AF;
$footer-background: #020E32;
$dark-blue: #274BB9;
$light-gray-blue: #D9E2F5;

//text
$blue-text-color: #274293;
$dark-blue-text-color: #041C55;

// Trainer colors
$trainer-accent-blue: #17348B;
$trainer-default-bg: #EEF3FD;
$trainer-accent-green: #23E964;

// buttons
$orange-color-base: #FF7F45;
$orange-color-accent: #FF471E;
$orange-color-hover: #FF3D0D;
$orange-color-hover-second: #FF4B1F;
$orange-color-disabled: #CED2E1;
$orange-gradient: linear-gradient(to bottom, $orange-color-base 0%, $orange-color-accent 100%);
$orange-gradient-hover: linear-gradient(to bottom, $orange-color-hover 0%, $orange-color-hover-second 100%);

$restart-color-base: #A9B3D4;
$restart-color-hover: #8591B9;

$to-mission-color: #D1D8EC;

// skills-colors
$imagination-color: #8C62C1;
$memory-color: #5BBF4A;
$focus-color: #E24067;
$mind-color: #4C8EDB;
$logic-color: #F39C19;

// skills-filter
$skills-filter-base: rgba(#7A92D8, .25);
$skills-filter-pressed: rgba(#7A92D8, .60);

// media queries vars
$mq-breakpoints: (
  mobile-sm: 320px,
  mobile: 320px,
  tablet: 768px,
  desktop: 1025px,
  wide: 1400px,
  desktopAd: 810px,
  mobileLandscape: 480px
);

//font
$ptsans: 'PTSans', sans-serif;

// shadows
$shadow: 0 5px 12px rgba(67, 101, 153, .11);
$inner-shadow: inset 0 5px 8px rgba(0, 28, 69, .25);

@import '~sass-mq/_mq.scss';

@mixin font-face($name, $filename, $path, $weight:normal, $style:normal) {
  @font-face {
    font-family: $name;
    src: url($path + $filename + '.woff2') format('woff2'),
      url($path + $filename + '.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin edit-cell-sudoku($borderColor, $color, $background) {
  border: 2px solid $borderColor;
  position: relative;
  color: $color;
  width: 59px;
  height: 59px;
  cursor: auto;

  &:before {
    content: '';
    position: absolute;
    background: $background;
    width: 100%;
    height: 100%;
  }
}

$background-cell-color: rgba(146, 154, 170, 0.1);
$mobile: 580px;

@mixin edit-cell-sudoku($borderColor, $color, $background) {
  border: 2px solid $borderColor;
  position: relative;
  color: $color;
  cursor: auto;

  &:before {
    content: '';
    position: absolute;
    background: $background;
    width: 100%;
    height: 100%;
  }
}

.edit-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #fff;
  margin: 1px;
  cursor: pointer;
  color: $mind-color;
  font-weight: 700;

  &_radius-top-left {
    border-top-left-radius: 6px;

    @include mq($from: tablet) {
      border-top-left-radius: 10px;
    }
  }

  &_radius-top-right {
    border-top-right-radius: 6px;

    @include mq($from: tablet) {
      border-top-right-radius: 10px;
    }
  }

  &_radius-bottom-left {
    border-bottom-left-radius: 6px;

    @include mq($from: tablet) {
      border-bottom-left-radius: 10px;
    }
  }

  &_radius-bottom-right {
    border-bottom-right-radius: 6px;

    @include mq($from: tablet) {
      border-bottom-right-radius: 10px;
    }
  }

  &_error {
    @include edit-cell-sudoku(
      rgba(242, 23, 23, 0.5),
      #F21717,
      rgba(242, 23, 23, 0.1)
    );
  }

  &_success {
    @include edit-cell-sudoku(
      rgba(29, 187, 92, 0.5),
      #1DBB5C,
      rgba(29, 187, 92, 0.1)
    );
  }

  &_active {
    @include edit-cell-sudoku(
      #C7CDD8,
      $mind-color,
      $background-cell-color
    );
  }

  &__notes {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
  }

  &__note {
    font-weight: 700;
    color: $mind-color;
    font-size: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2px;
    margin-top: 2px;

    @include mq($from: $mobile) {
      font-size: 13px;
    }

    @include mq($from: tablet) {
      font-size: 14px;
    }

    &_mini {
      margin-left: 1px;
      margin-top: 1px;

      @include mq($from: $mobile) {
        margin-left: 0;
      }
    }
  }
}
