.ReactModal {
  &__Overlay {
    z-index: 100;
  }

  &__Content {
    $content: &;
    outline: 0;

    &.scale {
      transition: transform .4s ease-out;
      transform: scale(0);

      &#{$content}--after-open {
        transform: scale(1);
      }

      &#{$content}--before-close {
        transform: scale(0);
      }
    }
  }
}
