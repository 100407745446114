//site colors
$burger-background: #274293;
$profile-background: #1A30A5;
$profile-background-pressed: #0228AF;
$footer-background: #020E32;
$dark-blue: #274BB9;
$light-gray-blue: #D9E2F5;

//text
$blue-text-color: #274293;
$dark-blue-text-color: #041C55;

// Trainer colors
$trainer-accent-blue: #17348B;
$trainer-default-bg: #EEF3FD;
$trainer-accent-green: #23E964;

// buttons
$orange-color-base: #FF7F45;
$orange-color-accent: #FF471E;
$orange-color-hover: #FF3D0D;
$orange-color-hover-second: #FF4B1F;
$orange-color-disabled: #CED2E1;
$orange-gradient: linear-gradient(to bottom, $orange-color-base 0%, $orange-color-accent 100%);
$orange-gradient-hover: linear-gradient(to bottom, $orange-color-hover 0%, $orange-color-hover-second 100%);

$restart-color-base: #A9B3D4;
$restart-color-hover: #8591B9;

$to-mission-color: #D1D8EC;

// skills-colors
$imagination-color: #8C62C1;
$memory-color: #5BBF4A;
$focus-color: #E24067;
$mind-color: #4C8EDB;
$logic-color: #F39C19;

// skills-filter
$skills-filter-base: rgba(#7A92D8, .25);
$skills-filter-pressed: rgba(#7A92D8, .60);

// media queries vars
$mq-breakpoints: (
  mobile-sm: 320px,
  mobile: 320px,
  tablet: 768px,
  desktop: 1025px,
  wide: 1400px,
  desktopAd: 810px,
  mobileLandscape: 480px
);

//font
$ptsans: 'PTSans', sans-serif;

// shadows
$shadow: 0 5px 12px rgba(67, 101, 153, .11);
$inner-shadow: inset 0 5px 8px rgba(0, 28, 69, .25);

@import '~sass-mq/_mq.scss';

@mixin font-face($name, $filename, $path, $weight:normal, $style:normal) {
  @font-face {
    font-family: $name;
    src: url($path + $filename + '.woff2') format('woff2'),
      url($path + $filename + '.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin edit-cell-sudoku($borderColor, $color, $background) {
  border: 2px solid $borderColor;
  position: relative;
  color: $color;
  width: 59px;
  height: 59px;
  cursor: auto;

  &:before {
    content: '';
    position: absolute;
    background: $background;
    width: 100%;
    height: 100%;
  }
}

$mobile: 480px;
$green-color: #1DBB5C;
$red-color: #FF3509;

.trainer-tangram {
  margin: 0 auto;
  position: relative;

  &__field {
    width: 586px;
    height: 456px;
    background: transparent;
    position: absolute;
    top: 110px;
    z-index: 5;
    left: 50%;
    transform: translate(-50%);
    border-radius: 15px;

    @include mq($from: $mobile, $until: tablet) {
      width: 100%;
      top: 80px;
      height: 414px;
    }

    @include mq($until: $mobile) {
      width: 100%;
      top: 40px;
      height: 300px;
      border-radius: 0;
    }

    &_ended {
      top: 213px;

      @include mq($from: $mobile, $until: tablet) {
        top: 181px;
      }

      @include mq($until: $mobile) {
        top: 161px;
      }
    }

    &_correct {
      border: solid 3px $green-color;
    }

    &_incorrect {
      border: solid 3px $red-color;
    }

    &_neutral {
      border: 3px solid #A0A6B9;
    }
  }

  &__correct-icon {
    width: 30px;
    height: 30px;
    background: $green-color;
    border-radius: 50%;
    position: absolute;
    right: -8px;
    top: -6px;
    z-index: 10;

    &:after {
      content: "";
      border: solid;
      border-color: white;
      position: absolute;
      width: 7px;
      height: 12px;
      border-width: 0 4px 4px 0;
      transform: translate(-50%, -50%) rotate(45deg);
      left: 50%;
      top: 45%;
    }
  }

  &__incorrect {
    width: 30px;
    height: 30px;
    background: $red-color;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(35%, -35%);
  }

  &__incorrect-icon {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 15px;
    height: 15px;
    z-index: 10;
  }

  &__button {
    margin: 8px auto;
    position: fixed;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;
    width: 120px;
    font-size: 12px;
    line-height: 40px;
    height: 40px;

    @include mq($from: tablet) {
      margin: 40px auto 20px;
      width: 206px;
      font-size: 14px;
      line-height: 50px;
      height: 50px;
    }
  }
}
