//site colors
$burger-background: #274293;
$profile-background: #1A30A5;
$profile-background-pressed: #0228AF;
$footer-background: #020E32;
$dark-blue: #274BB9;
$light-gray-blue: #D9E2F5;

//text
$blue-text-color: #274293;
$dark-blue-text-color: #041C55;

// Trainer colors
$trainer-accent-blue: #17348B;
$trainer-default-bg: #EEF3FD;
$trainer-accent-green: #23E964;

// buttons
$orange-color-base: #FF7F45;
$orange-color-accent: #FF471E;
$orange-color-hover: #FF3D0D;
$orange-color-hover-second: #FF4B1F;
$orange-color-disabled: #CED2E1;
$orange-gradient: linear-gradient(to bottom, $orange-color-base 0%, $orange-color-accent 100%);
$orange-gradient-hover: linear-gradient(to bottom, $orange-color-hover 0%, $orange-color-hover-second 100%);

$restart-color-base: #A9B3D4;
$restart-color-hover: #8591B9;

$to-mission-color: #D1D8EC;

// skills-colors
$imagination-color: #8C62C1;
$memory-color: #5BBF4A;
$focus-color: #E24067;
$mind-color: #4C8EDB;
$logic-color: #F39C19;

// skills-filter
$skills-filter-base: rgba(#7A92D8, .25);
$skills-filter-pressed: rgba(#7A92D8, .60);

// media queries vars
$mq-breakpoints: (
  mobile-sm: 320px,
  mobile: 320px,
  tablet: 768px,
  desktop: 1025px,
  wide: 1400px,
  desktopAd: 810px,
  mobileLandscape: 480px
);

//font
$ptsans: 'PTSans', sans-serif;

// shadows
$shadow: 0 5px 12px rgba(67, 101, 153, .11);
$inner-shadow: inset 0 5px 8px rgba(0, 28, 69, .25);

@import '~sass-mq/_mq.scss';

@mixin font-face($name, $filename, $path, $weight:normal, $style:normal) {
  @font-face {
    font-family: $name;
    src: url($path + $filename + '.woff2') format('woff2'),
      url($path + $filename + '.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin edit-cell-sudoku($borderColor, $color, $background) {
  border: 2px solid $borderColor;
  position: relative;
  color: $color;
  width: 59px;
  height: 59px;
  cursor: auto;

  &:before {
    content: '';
    position: absolute;
    background: $background;
    width: 100%;
    height: 100%;
  }
}

.trainers-page {
  background-color: #1A3890;
  padding: 121px 0;
  font-family: $ptsans;
  height: 100%;
  box-sizing: border-box;

  &__container {
    max-width: 1211px;
    margin: 0 auto;
    padding: 12px 24px;
    position: relative;
    box-sizing: border-box;
  }

  &__title {
    font-size: 40px;
    color: #fff;
    font-style: normal;
    font-weight: bold;
    margin: 0;
  }

  &__filter {
    display: flex;
    margin-top: 50px;
    overflow: auto;
    padding-bottom: 10px;
  }

  &__filter-button_all,
  &__filter-button_memory,
  &__filter-button_logic,
  &__filter-button_focus,
  &__filter-button_mind,
  &__filter-button_imagination {
    transition: .15s;
    display: flex;
    align-items: center;
    outline: none;
    color: #fff;
    line-height: 36px;
    font-size: 16px;
    padding: 0 8px 0 14px;
    background-color: #324FA2;
    margin-left: 10px;
    border-radius: 50px;
    cursor: pointer;
  }

  &__filter-button {
    display: flex;
    align-items: center;
    background-color: rgba(122, 146, 216, 0.25);
    color: #fff;
    line-height: 36px;
    font-size: 16px;
    padding: 0 8px 0 14px;
    border-radius: 50px;
    cursor: pointer;
    margin-left: 10px;
    outline: none;
    transition: .15s;

    &:hover {
      background-color: rgba(122, 146, 216, 0.6);
    }

    &:first-child {
      margin-left: 0;
      padding: 0 18px;
    }

    &_all {
      padding: 0 18px;
      margin-left: 0;
      background-color: rgba(26, 117, 255, 0.5);
    }

    &_memory {
      background-color: $memory-color;
      box-shadow: 0px 5px 15px rgba(46, 235, 14, 0.5);
      margin-left: 10px;
    }

    &_focus {
      background-color: $focus-color;
      box-shadow: 0px 5px 15px rgba(255, 47, 84, 0.86);
      margin-left: 10px;
    }

    &_logic {
      background-color: $logic-color;
      box-shadow: 0px 5px 15px rgba(244, 166, 26, 0.5);
      margin-left: 10px;
    }

    &_mind {
      background-color: $mind-color;
      box-shadow: 0px 5px 15px rgba(86, 152, 224, 0.5);
      margin-left: 10px;
    }

    &_imagination {
      background-color: $imagination-color;
      box-shadow: 0px 5px 15px rgba(151, 109, 200, 0.5);
      margin-left: 10px;
    }
  }

  &__filter-count {
    margin: 0 0 0 6px;
    line-height: 1.375;
    border-radius: 50%;
    margin: 0 0 0 6px;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: #FFFFFF;
    width: 22px;
    height: 22px;

    &_focus {
      background-color: $focus-color;
    }

    &_memory {
      background-color: $memory-color;
    }

    &_logic {
      background-color: $logic-color;
    }

    &_mind {
      background-color: $mind-color;
    }

    &_imagination {
      background-color: $imagination-color;
    }
  }

  &__preview-cards-container {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    margin-left: -30px;
    justify-content: flex-start;
  }

  &__trainer-card {
    padding-left: 30px;
    margin-bottom: 30px;
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 100%;
    height: 320px;
    opacity: 0;

    &_mobile {
      height: 360px;
    }

    @include mq($until: mobileLandscape) {
      height: 241px;

      &_mobile {
        height: 281px;
      }
    }

    @include mq($from: 620px) {
      flex: 1 1 50%;
      max-width: 50%;
    }

    @include mq($from: desktop) {
      flex: 1 1 33%;
      max-width: 33.3%;
    }

    @for $i from 1 through 15 {
      &:nth-child(#{$i}) {
        animation: fadeOut 0.6s ease-out;
        animation-delay: #{($i - 1) * 0.2}s;
        animation-fill-mode: forwards;
      }
    }
  }

  .lds-ring {
    display: inline-block;
    position: absolute;
    width: 64px;
    height: 64px;
    bottom: -70px;
    left: 50%;
    transform: translateX(-50%);
  }

  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }

  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }

  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }

  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

