//site colors
$burger-background: #274293;
$profile-background: #1A30A5;
$profile-background-pressed: #0228AF;
$footer-background: #020E32;
$dark-blue: #274BB9;
$light-gray-blue: #D9E2F5;

//text
$blue-text-color: #274293;
$dark-blue-text-color: #041C55;

// Trainer colors
$trainer-accent-blue: #17348B;
$trainer-default-bg: #EEF3FD;
$trainer-accent-green: #23E964;

// buttons
$orange-color-base: #FF7F45;
$orange-color-accent: #FF471E;
$orange-color-hover: #FF3D0D;
$orange-color-hover-second: #FF4B1F;
$orange-color-disabled: #CED2E1;
$orange-gradient: linear-gradient(to bottom, $orange-color-base 0%, $orange-color-accent 100%);
$orange-gradient-hover: linear-gradient(to bottom, $orange-color-hover 0%, $orange-color-hover-second 100%);

$restart-color-base: #A9B3D4;
$restart-color-hover: #8591B9;

$to-mission-color: #D1D8EC;

// skills-colors
$imagination-color: #8C62C1;
$memory-color: #5BBF4A;
$focus-color: #E24067;
$mind-color: #4C8EDB;
$logic-color: #F39C19;

// skills-filter
$skills-filter-base: rgba(#7A92D8, .25);
$skills-filter-pressed: rgba(#7A92D8, .60);

// media queries vars
$mq-breakpoints: (
  mobile-sm: 320px,
  mobile: 320px,
  tablet: 768px,
  desktop: 1025px,
  wide: 1400px,
  desktopAd: 810px,
  mobileLandscape: 480px
);

//font
$ptsans: 'PTSans', sans-serif;

// shadows
$shadow: 0 5px 12px rgba(67, 101, 153, .11);
$inner-shadow: inset 0 5px 8px rgba(0, 28, 69, .25);

@import '~sass-mq/_mq.scss';

@mixin font-face($name, $filename, $path, $weight:normal, $style:normal) {
  @font-face {
    font-family: $name;
    src: url($path + $filename + '.woff2') format('woff2'),
      url($path + $filename + '.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin edit-cell-sudoku($borderColor, $color, $background) {
  border: 2px solid $borderColor;
  position: relative;
  color: $color;
  width: 59px;
  height: 59px;
  cursor: auto;

  &:before {
    content: '';
    position: absolute;
    background: $background;
    width: 100%;
    height: 100%;
  }
}

.trainer-header {
  background: #274BB9;
  $root: &;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;

  &__up {
    min-height: 68px;
    position: relative;
    padding: 0 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    background: #02217D;

    @include mq($from: tablet) {
      min-height: 48px;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__down {
    height: 40px;

    @include mq($from: tablet) {
      height: 72px;
    }

    @include mq($until: 500px) {
      &_mobile {
        height: 56px;
      }
    }
  }

  &__left {
    display: flex;
    align-items: center;
    flex-basis: 100%;
    justify-content: center;

    @include mq($from: tablet) {
      flex-basis: auto;
    }
  }

  &__segment {
    padding-right: 10px;
    padding-left: 10px;
    flex-grow: 1;

    &:first-child {
      padding-left: 0;
    }

    &:nth-child(2) {
      flex-grow: 20;
    }

    &:last-child {
      padding-right: 0;
    }

    &_side {
      @include mq($until: desktop) {
        width: 50px;
      }
    }

    &_center {
      @include mq($until: desktop) {
        padding: 0;
      }
    }
  }

  &__exit {
    cursor: pointer;
    display: block;
    width: 18px;
    height: 18px;
    border: 0;
    position: absolute;
    left: 12px;
    top: 12px;

    @include mq($from: tablet) {
      position: relative;
      top: auto;
      left: auto;
    }

    @media screen and (max-width: 700px) {
      width: 15px;
      height: 15px;
    }

    &#{$root}__stage-condition {
      @include mq($until: 700px) {
        margin-top: 15px;
      }

      @include mq($until: mobileLandscape) {
        margin-top: 5px;
      }
    }
  }

  &__exit-icon {
    fill: #8699d6;
    width: 100%;
    height: 100%;
  }

  &__timeline-holder {
    z-index: 2;
    position: relative;
    height: 100%;
  }

  &__stage-number {
    color: $trainer-accent-blue;
    font-size: 16px;
    font-family: $ptsans;
    font-style: normal;
    margin: auto;

    @include mq($until: mobileLandscape) {
      font-size: 10px;
    }
  }

  &__breadcrumbs {
    margin-left: 0;

    @include mq($from: tablet) {
      margin-left: 24px;
    }
  }
}
