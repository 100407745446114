//site colors
$burger-background: #274293;
$profile-background: #1A30A5;
$profile-background-pressed: #0228AF;
$footer-background: #020E32;
$dark-blue: #274BB9;
$light-gray-blue: #D9E2F5;

//text
$blue-text-color: #274293;
$dark-blue-text-color: #041C55;

// Trainer colors
$trainer-accent-blue: #17348B;
$trainer-default-bg: #EEF3FD;
$trainer-accent-green: #23E964;

// buttons
$orange-color-base: #FF7F45;
$orange-color-accent: #FF471E;
$orange-color-hover: #FF3D0D;
$orange-color-hover-second: #FF4B1F;
$orange-color-disabled: #CED2E1;
$orange-gradient: linear-gradient(to bottom, $orange-color-base 0%, $orange-color-accent 100%);
$orange-gradient-hover: linear-gradient(to bottom, $orange-color-hover 0%, $orange-color-hover-second 100%);

$restart-color-base: #A9B3D4;
$restart-color-hover: #8591B9;

$to-mission-color: #D1D8EC;

// skills-colors
$imagination-color: #8C62C1;
$memory-color: #5BBF4A;
$focus-color: #E24067;
$mind-color: #4C8EDB;
$logic-color: #F39C19;

// skills-filter
$skills-filter-base: rgba(#7A92D8, .25);
$skills-filter-pressed: rgba(#7A92D8, .60);

// media queries vars
$mq-breakpoints: (
  mobile-sm: 320px,
  mobile: 320px,
  tablet: 768px,
  desktop: 1025px,
  wide: 1400px,
  desktopAd: 810px,
  mobileLandscape: 480px
);

//font
$ptsans: 'PTSans', sans-serif;

// shadows
$shadow: 0 5px 12px rgba(67, 101, 153, .11);
$inner-shadow: inset 0 5px 8px rgba(0, 28, 69, .25);

@import '~sass-mq/_mq.scss';

@mixin font-face($name, $filename, $path, $weight:normal, $style:normal) {
  @font-face {
    font-family: $name;
    src: url($path + $filename + '.woff2') format('woff2'),
      url($path + $filename + '.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin edit-cell-sudoku($borderColor, $color, $background) {
  border: 2px solid $borderColor;
  position: relative;
  color: $color;
  width: 59px;
  height: 59px;
  cursor: auto;

  &:before {
    content: '';
    position: absolute;
    background: $background;
    width: 100%;
    height: 100%;
  }
}

.footer {
  background-color: $footer-background;
  z-index: 3;

  &__holder {
    max-width: 1138px;
    margin: 0 auto;
    padding: 0 20px 36px;

    @include mq($from: tablet) {
      display: flex;
      flex-wrap: wrap;
      padding: 0 60px 18px;
    }

    @include mq($from: tablet) {
      padding: 0 60px 25px;
    }
  }

  &__projects {
    flex-basis: 100%;

    @include mq($from: desktop) {
      flex-basis: 60%;
      order: 2;
    }
  }

  &__confidential {
    margin-top: 34px;
    text-align: center;

    @include mq($from: tablet) {
      flex: 1 0 auto;
      order: 1;
      margin-top: 37px;
    }

    @include mq($from: desktop) {
      margin-top: 25px;
    }
  }

  &__created-by {
    margin-top: 42px;

    @include mq($from: tablet) {
      flex: 1 0 auto;
      order: 2;
      margin-top: 37px;
    }

    @include mq($from: desktop) {
      margin-top: 25px;
    }
  }

  &__socials {
    margin-top: 15px;
    height: 22px;
    text-align: center;

    @include mq($from: tablet) {
      flex: 0 1 50%;
      justify-content: flex-end;
    }

    @include mq($from: desktop) {
      flex-basis: 100%;
      order: 3;
      margin-top: 0;
    }
  }

  &__group {
    display: flex;
    justify-content: space-between;
    margin-top: 45px;
    height: 60px;

    @include mq($from: tablet) {
      flex: 0 1 50%;
      margin-top: 15px;
    }

    @include mq($from: desktop) {
      flex-basis: 40%;
      order: 1;
      justify-content: flex-start;
      margin-top: 25px;
    }
  }

  &__contacts,
  &__logo {
    text-align: left;
    flex: 1 0 50%;

    @include mq($from: mobile) {
      text-align: center;
    }

    @include mq($from: tablet) {
      text-align: left;
    }

    @include mq($from: desktop) {
      flex-basis: auto;
    }
  }

  &__logo {
    @include mq($from: tablet) {
      text-align: left;
      flex: 0 1 50%;
    }

    @include mq($from: desktop) {
      flex-basis: auto;
      margin-right: 60px;
    }
  }

  &__line {
    @include mq($from: tablet) {
      flex-basis: 100%;
      display: flex;
      align-items: center;
    }

    @include mq($from: desktop) {
      order: 4;
    }
  }

  &__logo-holder {
    width: 95px;
    height: 43px;

    @include mq($from: tablet) {
      width: 115px;
      height: 52px;
    }
  }
}
