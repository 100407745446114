//site colors
$burger-background: #274293;
$profile-background: #1A30A5;
$profile-background-pressed: #0228AF;
$footer-background: #020E32;
$dark-blue: #274BB9;
$light-gray-blue: #D9E2F5;

//text
$blue-text-color: #274293;
$dark-blue-text-color: #041C55;

// Trainer colors
$trainer-accent-blue: #17348B;
$trainer-default-bg: #EEF3FD;
$trainer-accent-green: #23E964;

// buttons
$orange-color-base: #FF7F45;
$orange-color-accent: #FF471E;
$orange-color-hover: #FF3D0D;
$orange-color-hover-second: #FF4B1F;
$orange-color-disabled: #CED2E1;
$orange-gradient: linear-gradient(to bottom, $orange-color-base 0%, $orange-color-accent 100%);
$orange-gradient-hover: linear-gradient(to bottom, $orange-color-hover 0%, $orange-color-hover-second 100%);

$restart-color-base: #A9B3D4;
$restart-color-hover: #8591B9;

$to-mission-color: #D1D8EC;

// skills-colors
$imagination-color: #8C62C1;
$memory-color: #5BBF4A;
$focus-color: #E24067;
$mind-color: #4C8EDB;
$logic-color: #F39C19;

// skills-filter
$skills-filter-base: rgba(#7A92D8, .25);
$skills-filter-pressed: rgba(#7A92D8, .60);

// media queries vars
$mq-breakpoints: (
  mobile-sm: 320px,
  mobile: 320px,
  tablet: 768px,
  desktop: 1025px,
  wide: 1400px,
  desktopAd: 810px,
  mobileLandscape: 480px
);

//font
$ptsans: 'PTSans', sans-serif;

// shadows
$shadow: 0 5px 12px rgba(67, 101, 153, .11);
$inner-shadow: inset 0 5px 8px rgba(0, 28, 69, .25);

@import '~sass-mq/_mq.scss';

@mixin font-face($name, $filename, $path, $weight:normal, $style:normal) {
  @font-face {
    font-family: $name;
    src: url($path + $filename + '.woff2') format('woff2'),
      url($path + $filename + '.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin edit-cell-sudoku($borderColor, $color, $background) {
  border: 2px solid $borderColor;
  position: relative;
  color: $color;
  width: 59px;
  height: 59px;
  cursor: auto;

  &:before {
    content: '';
    position: absolute;
    background: $background;
    width: 100%;
    height: 100%;
  }
}

.mission-results {
  background-color: #07C5A3;
  width: 100%;
  min-height: 100%;
  padding: 64px 20px 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: 10px;

  @include mq($from: 622px) {
    padding: 35px 30px 40px;
    flex-direction: row;
    border-radius: 15px 0 0 15px;
  }

  &__info {
    @include mq($from: 622px) {
      justify-content: space-between;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: flex-start;
      flex: 1 1 65%;
      display: flex;
    }
  }

  &__rating {
    width: 100%;
    height: 93px;
    background-color: white;
    border-radius: 10px;
    flex: 0 1 auto;
    padding: 15px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include mq($from: 622px) {
      width: auto;
      margin-right: 20px;
      flex: 1 1 auto;
    }
  }

  &__gift {
    height: 93px;
    background-color: white;
    border-radius: 10px;
    flex: 0 1 auto;
    padding: 15px 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    align-items: center;

    @include mq($from: 622px) {
      width: auto;
      margin-top: 0;
      flex: 2 1 auto;
    }
  }

  &__header {
    display: none;

    @include mq($from: 960px) {
      flex: 1 1 35%;
      padding: 40px;
      display: block;
    }
  }

  &__title {
    font-family: $ptsans;
    font-weight: 900;
    font-size: 40px;
    letter-spacing: 1px;
    color: white;
    line-height: 1.1;
  }

  &__name {
    font-family: $ptsans;
    font-size: 18px;
    line-height: 1.55;
    letter-spacing: 1px;
    color: white;
    opacity: .7;
    font-weight: 400;
  }

  &__skills {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 -10px;

    @include mq($from: 622px) {
      flex-wrap: nowrap;
      justify-content: flex-start;
      margin-left: 0;
      flex: 1 1 100%;
    }
  }

  &__skill {
    padding: 0 10px;
    box-sizing: border-box;
    margin-top: 20px;
    width: 50%;

    @include mq($from: 470px) {
      width: 33.3%;
    }

    @include mq($from: 540px) {
      width: 25%;
    }
  }

  &__label {
    font-family: $ptsans;
    font-weight: 400;
    font-size: 14px;
    color: #9DA4B8;
    line-height: 1.2;
  }

  &__value {
    font-family: $ptsans;
    font-weight: 900;
    font-size: 24px;
    color: $blue-text-color;
    line-height: 1;
    margin-top: 18px;
  }

  &__gift-box {
    width: 70px;
    height: 70px;
  }

  &__close-btn {
    position: absolute;
    z-index: 2;
    font-family: $ptsans;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 1.83;
    top: 20px;
    right: 20px;
    color: #213976;
    cursor: pointer;

    @include mq($from: 622px) {
      display: none;
    }
  }

  &__close-icon {
    width: 24px;
    height: 24px;
    fill: white;
    vertical-align: bottom;
    margin-left: 10px;
  }
}
