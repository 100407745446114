//site colors
$burger-background: #274293;
$profile-background: #1A30A5;
$profile-background-pressed: #0228AF;
$footer-background: #020E32;
$dark-blue: #274BB9;
$light-gray-blue: #D9E2F5;

//text
$blue-text-color: #274293;
$dark-blue-text-color: #041C55;

// Trainer colors
$trainer-accent-blue: #17348B;
$trainer-default-bg: #EEF3FD;
$trainer-accent-green: #23E964;

// buttons
$orange-color-base: #FF7F45;
$orange-color-accent: #FF471E;
$orange-color-hover: #FF3D0D;
$orange-color-hover-second: #FF4B1F;
$orange-color-disabled: #CED2E1;
$orange-gradient: linear-gradient(to bottom, $orange-color-base 0%, $orange-color-accent 100%);
$orange-gradient-hover: linear-gradient(to bottom, $orange-color-hover 0%, $orange-color-hover-second 100%);

$restart-color-base: #A9B3D4;
$restart-color-hover: #8591B9;

$to-mission-color: #D1D8EC;

// skills-colors
$imagination-color: #8C62C1;
$memory-color: #5BBF4A;
$focus-color: #E24067;
$mind-color: #4C8EDB;
$logic-color: #F39C19;

// skills-filter
$skills-filter-base: rgba(#7A92D8, .25);
$skills-filter-pressed: rgba(#7A92D8, .60);

// media queries vars
$mq-breakpoints: (
  mobile-sm: 320px,
  mobile: 320px,
  tablet: 768px,
  desktop: 1025px,
  wide: 1400px,
  desktopAd: 810px,
  mobileLandscape: 480px
);

//font
$ptsans: 'PTSans', sans-serif;

// shadows
$shadow: 0 5px 12px rgba(67, 101, 153, .11);
$inner-shadow: inset 0 5px 8px rgba(0, 28, 69, .25);

@import '~sass-mq/_mq.scss';

@mixin font-face($name, $filename, $path, $weight:normal, $style:normal) {
  @font-face {
    font-family: $name;
    src: url($path + $filename + '.woff2') format('woff2'),
      url($path + $filename + '.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin edit-cell-sudoku($borderColor, $color, $background) {
  border: 2px solid $borderColor;
  position: relative;
  color: $color;
  width: 59px;
  height: 59px;
  cursor: auto;

  &:before {
    content: '';
    position: absolute;
    background: $background;
    width: 100%;
    height: 100%;
  }
}

.trainer-card-front {
  padding: 20px;
  $root: &;

  &__title {
    font-size: 20px;
    font-weight: 700;
    font-family: $ptsans;
    color: #1A3890;
    margin: 0 5px 0 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__points {
    position: relative;
    margin-top: 18px;
    display: flex;

    #{$root}_hovered & {
      flex-direction: column;
    }
  }

  &__image {
    width: 100%;
    height: 186px;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    position: absolute;
    bottom: 0;
    left: 0;

    @include mq($until: mobileLandscape) {
      height: 90px;
    }

    #{$root}_train & {
      height: 107px;
    }
  }

  &__start {
    transition: .2s;
    position: absolute;
    width: 66px;
    height: 66px;
    border-radius: 50%;
    background: linear-gradient(180deg, $orange-color-base 0%, $orange-color-accent 100%);
    box-shadow: 0px 6px 25px rgba(238, 57, 0, 0.84);
    display: flex;
    cursor: pointer;

    &:hover {
      background: linear-gradient(180deg, #FF3D0E 0%, $orange-color-hover-second 100%);
      box-shadow: 0px 3px 10px rgba(238, 57, 0, 0.7);
    }

    #{$root}_train & {
      left: unset;
      transform: none;
      bottom: 30px;
      right: 30px;
    }

    @include mq($from: mobileLandscape) {
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
    }

    @include mq($until: mobileLandscape) {
      width: 48px;
      height: 48px;
      right: 22px;
      bottom: 30px;
    }
  }

  &__icon {
    width: 22px;
    height: 27px;
    position: absolute;
    top: 50%;
    left: 50%;
    padding-left: 5px;
    transform: translate(-50%, -50%);

    @include mq($until: mobileLandscape) {
      width: 16px;
      height: 22px;
    }
  }

  &__button {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
  }
}
