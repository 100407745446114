//site colors
$burger-background: #274293;
$profile-background: #1A30A5;
$profile-background-pressed: #0228AF;
$footer-background: #020E32;
$dark-blue: #274BB9;
$light-gray-blue: #D9E2F5;

//text
$blue-text-color: #274293;
$dark-blue-text-color: #041C55;

// Trainer colors
$trainer-accent-blue: #17348B;
$trainer-default-bg: #EEF3FD;
$trainer-accent-green: #23E964;

// buttons
$orange-color-base: #FF7F45;
$orange-color-accent: #FF471E;
$orange-color-hover: #FF3D0D;
$orange-color-hover-second: #FF4B1F;
$orange-color-disabled: #CED2E1;
$orange-gradient: linear-gradient(to bottom, $orange-color-base 0%, $orange-color-accent 100%);
$orange-gradient-hover: linear-gradient(to bottom, $orange-color-hover 0%, $orange-color-hover-second 100%);

$restart-color-base: #A9B3D4;
$restart-color-hover: #8591B9;

$to-mission-color: #D1D8EC;

// skills-colors
$imagination-color: #8C62C1;
$memory-color: #5BBF4A;
$focus-color: #E24067;
$mind-color: #4C8EDB;
$logic-color: #F39C19;

// skills-filter
$skills-filter-base: rgba(#7A92D8, .25);
$skills-filter-pressed: rgba(#7A92D8, .60);

// media queries vars
$mq-breakpoints: (
  mobile-sm: 320px,
  mobile: 320px,
  tablet: 768px,
  desktop: 1025px,
  wide: 1400px,
  desktopAd: 810px,
  mobileLandscape: 480px
);

//font
$ptsans: 'PTSans', sans-serif;

// shadows
$shadow: 0 5px 12px rgba(67, 101, 153, .11);
$inner-shadow: inset 0 5px 8px rgba(0, 28, 69, .25);

@import '~sass-mq/_mq.scss';

@mixin font-face($name, $filename, $path, $weight:normal, $style:normal) {
  @font-face {
    font-family: $name;
    src: url($path + $filename + '.woff2') format('woff2'),
      url($path + $filename + '.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin edit-cell-sudoku($borderColor, $color, $background) {
  border: 2px solid $borderColor;
  position: relative;
  color: $color;
  width: 59px;
  height: 59px;
  cursor: auto;

  &:before {
    content: '';
    position: absolute;
    background: $background;
    width: 100%;
    height: 100%;
  }
}

.trainer-screen {
  $root: &;

  font-family: $ptsans;
  text-align: center;
  background: url('../../static/image/map-background.png');
  flex: 1 1 auto;
  overflow: hidden;
  padding: 0 20px 0;

  &__holder {
    max-width: 708px;
    background: #EEF3FD;
    box-shadow: 0px 10px 30px rgba(0, 38, 96, 0.3);
    border-radius: 40px;
    padding: 30px 40px;
    box-sizing: border-box;
    margin: 40px auto;
    position: relative;

    @include mq($from: tablet) {
      margin: 150px auto;
      padding: 52px 63px;
    }
  }

  &__part {
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 0;
    transform: translateY(100%) translateX(100px);
    left: 50%;
  }

  &__part-icon {
    width: 100%;
    height: 100%;
  }

  &__label {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    color: #A0A6B9;
    letter-spacing: 1px;
    margin-bottom: 5px;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 25px;
    color: $blue-text-color;
    font-size: 24px;
    line-height: 32px;
    font-weight: 900;
  }

  &__content {
    font-size: 18px;
    line-height: 26px;
    white-space: pre-line;

    > p {
      margin-top: 0;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include mq($until: mobileLandscape) {
      font-size: 16px;
    }
  }

  &__button {
    margin-top: 50px;
    width: 100%;

    @include mq($from: tablet) {
      max-width: 268px;
    }

    &_exit {
      margin-top: 25px;
    }
  }

  &__button-lose {
    margin-top: 50px;
  }

  &__gift-container {
    margin: 50px auto 0;
    width: 188px;
    height: 170.9px;
    background-image: url(/static/media/container.effcf0aa.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__gift-preview {
    width: 152.59px;
    height: 160.22px;
    margin-top: 7px;
    margin-left: 15px;
  }

  &__description-window {
    padding: 40px 60px;

    @include mq($until: 600px) {
      padding: 40px 22px;
    }
  }

  &__show-video {
    margin: 30px auto 0;
    color: $orange-color-base;
    cursor: pointer;
    display: block;
    font-family: $ptsans;
    font-weight: 400;
  }

  &__foxman {
    display: none;

    @include mq($from: tablet) {
      display: block;
      position: absolute;
      height: 887px;
      width: 433px;
      right: 0;
      bottom: 0;
      transform: translate(45%, 90%);
    }
  }

  &__foxman-img {
    width: 100%;
    height: 100%;
  }
}
