//site colors
$burger-background: #274293;
$profile-background: #1A30A5;
$profile-background-pressed: #0228AF;
$footer-background: #020E32;
$dark-blue: #274BB9;
$light-gray-blue: #D9E2F5;

//text
$blue-text-color: #274293;
$dark-blue-text-color: #041C55;

// Trainer colors
$trainer-accent-blue: #17348B;
$trainer-default-bg: #EEF3FD;
$trainer-accent-green: #23E964;

// buttons
$orange-color-base: #FF7F45;
$orange-color-accent: #FF471E;
$orange-color-hover: #FF3D0D;
$orange-color-hover-second: #FF4B1F;
$orange-color-disabled: #CED2E1;
$orange-gradient: linear-gradient(to bottom, $orange-color-base 0%, $orange-color-accent 100%);
$orange-gradient-hover: linear-gradient(to bottom, $orange-color-hover 0%, $orange-color-hover-second 100%);

$restart-color-base: #A9B3D4;
$restart-color-hover: #8591B9;

$to-mission-color: #D1D8EC;

// skills-colors
$imagination-color: #8C62C1;
$memory-color: #5BBF4A;
$focus-color: #E24067;
$mind-color: #4C8EDB;
$logic-color: #F39C19;

// skills-filter
$skills-filter-base: rgba(#7A92D8, .25);
$skills-filter-pressed: rgba(#7A92D8, .60);

// media queries vars
$mq-breakpoints: (
  mobile-sm: 320px,
  mobile: 320px,
  tablet: 768px,
  desktop: 1025px,
  wide: 1400px,
  desktopAd: 810px,
  mobileLandscape: 480px
);

//font
$ptsans: 'PTSans', sans-serif;

// shadows
$shadow: 0 5px 12px rgba(67, 101, 153, .11);
$inner-shadow: inset 0 5px 8px rgba(0, 28, 69, .25);

@import '~sass-mq/_mq.scss';

@mixin font-face($name, $filename, $path, $weight:normal, $style:normal) {
  @font-face {
    font-family: $name;
    src: url($path + $filename + '.woff2') format('woff2'),
      url($path + $filename + '.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin edit-cell-sudoku($borderColor, $color, $background) {
  border: 2px solid $borderColor;
  position: relative;
  color: $color;
  width: 59px;
  height: 59px;
  cursor: auto;

  &:before {
    content: '';
    position: absolute;
    background: $background;
    width: 100%;
    height: 100%;
  }
}

.trainer-footer {
  background: #D9E2F5;
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 10;
  min-height: 56px;
  padding: 8px 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  @include mq($from: tablet) {
    padding: 20px 30px;
    min-height: 82px;
  }

  &__holder {
    display: flex;
    align-items: center;
    flex: 1 0 auto;
    justify-content: space-between;

    @include mq($from: 0, $until: tablet) {
      flex-flow: row wrap;
      justify-content: space-between;
    }
  }

  &__segment {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;

    &:last-child {
      padding-right: 0;
    }

    &:first-child {
      padding-left: 0;
    }

    @include mq($from: mobile-sm, $until: tablet) {
      padding-left: 0;
      padding-right: 0;
      width: auto;

      &:first-child {
        max-width: 70%;
      }
    }
  }

  &__status {
    display: flex;
    justify-content: center;

    @include mq($from: mobile-sm, $until: tablet) {
      order: 1;
      margin-top: 10px;
      width: 100%;
    }
  }

  &__help {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    max-width: 708px;
  }

  &__button {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    width: 120px;

    & .button {
      font-size: 12px;
      height: 40px;
      line-height: 40px;
    }

    @include mq($from: tablet) {
      width: 235px;

      & .button {
        font-size: 14px;
        height: 50px;
        line-height: 50px;
      }
    }
  }
}
