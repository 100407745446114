//site colors
$burger-background: #274293;
$profile-background: #1A30A5;
$profile-background-pressed: #0228AF;
$footer-background: #020E32;
$dark-blue: #274BB9;
$light-gray-blue: #D9E2F5;

//text
$blue-text-color: #274293;
$dark-blue-text-color: #041C55;

// Trainer colors
$trainer-accent-blue: #17348B;
$trainer-default-bg: #EEF3FD;
$trainer-accent-green: #23E964;

// buttons
$orange-color-base: #FF7F45;
$orange-color-accent: #FF471E;
$orange-color-hover: #FF3D0D;
$orange-color-hover-second: #FF4B1F;
$orange-color-disabled: #CED2E1;
$orange-gradient: linear-gradient(to bottom, $orange-color-base 0%, $orange-color-accent 100%);
$orange-gradient-hover: linear-gradient(to bottom, $orange-color-hover 0%, $orange-color-hover-second 100%);

$restart-color-base: #A9B3D4;
$restart-color-hover: #8591B9;

$to-mission-color: #D1D8EC;

// skills-colors
$imagination-color: #8C62C1;
$memory-color: #5BBF4A;
$focus-color: #E24067;
$mind-color: #4C8EDB;
$logic-color: #F39C19;

// skills-filter
$skills-filter-base: rgba(#7A92D8, .25);
$skills-filter-pressed: rgba(#7A92D8, .60);

// media queries vars
$mq-breakpoints: (
  mobile-sm: 320px,
  mobile: 320px,
  tablet: 768px,
  desktop: 1025px,
  wide: 1400px,
  desktopAd: 810px,
  mobileLandscape: 480px
);

//font
$ptsans: 'PTSans', sans-serif;

// shadows
$shadow: 0 5px 12px rgba(67, 101, 153, .11);
$inner-shadow: inset 0 5px 8px rgba(0, 28, 69, .25);

@import '~sass-mq/_mq.scss';

@mixin font-face($name, $filename, $path, $weight:normal, $style:normal) {
  @font-face {
    font-family: $name;
    src: url($path + $filename + '.woff2') format('woff2'),
      url($path + $filename + '.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin edit-cell-sudoku($borderColor, $color, $background) {
  border: 2px solid $borderColor;
  position: relative;
  color: $color;
  width: 59px;
  height: 59px;
  cursor: auto;

  &:before {
    content: '';
    position: absolute;
    background: $background;
    width: 100%;
    height: 100%;
  }
}

.open-map-locations {
  display: flex;
  justify-content: center;

  &__card {
    height: 465px;
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    max-width: 1211px;
    background: url('../../static/locations/london/london.jpeg');
    background-size: cover;
    margin: 0 11px;
    flex: 1 0 auto;

    @include mq($from: 616px) {
      height: 386px;
      background-position: center;
    }

    @include mq($from: desktop) {
      width: 1163px;
      margin: 0 57px;
    }
  }

  &__mask {
    background: linear-gradient(rgba(30, 79, 208, 0), ease, #1D4ECF);
    height: 50%;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  &__box {
    margin: 0 10px;
    position: relative;
    height: 100%;

    @include mq($from: 616px) {
      margin: 0 30px;
    }

    @include mq($from: desktop) {
      margin: 0 110px;
    }
  }

  &__info {
    position: absolute;
    left: 0;
    padding: 26px 20px 22px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    bottom: 10px;
    width: 100%;

    @include mq($from: 616px) {
      bottom: 42px;
    }

    @include mq($from: tablet) {
      display: flex;
      align-items: center;
    }
  }

  &__link {
    margin-top: 30px;

    @include mq($from: tablet) {
      margin: 0 0 0 100px;
      flex: 1 0 197px;
    }
  }

  &__text {
    font-family: $ptsans;
    font-weight: 400;
    line-height: 1.44;
    font-size: 18px;
    color: #041C55;
    text-align: left;
    margin: 0;
  }

  &__geo {
    display: block;
    position: absolute;
    top: 24px;
    left: 34px;

    @include mq($from: desktop) {
      top: 58px;
    }

    @include mq($from: desktop) {
      left: 110px;
    }
  }

  &__left,
  &__right {
    display: none;

    @include mq($from: desktop) {
      display: block;
      background: url('../../static/locations/moscow/moscow-background.jpg');
      height: 465px;
      border-radius: 15px;
      flex: auto;
      width: 1140px;
      height: 386px;
      background-position: center;
      position: relative;
    }
  }

  &__side-mask {
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(251, 253, 255, 0) 0%, #D2E1F4 100%);
    border-radius: 15px;
  }
}
