//site colors
$burger-background: #274293;
$profile-background: #1A30A5;
$profile-background-pressed: #0228AF;
$footer-background: #020E32;
$dark-blue: #274BB9;
$light-gray-blue: #D9E2F5;

//text
$blue-text-color: #274293;
$dark-blue-text-color: #041C55;

// Trainer colors
$trainer-accent-blue: #17348B;
$trainer-default-bg: #EEF3FD;
$trainer-accent-green: #23E964;

// buttons
$orange-color-base: #FF7F45;
$orange-color-accent: #FF471E;
$orange-color-hover: #FF3D0D;
$orange-color-hover-second: #FF4B1F;
$orange-color-disabled: #CED2E1;
$orange-gradient: linear-gradient(to bottom, $orange-color-base 0%, $orange-color-accent 100%);
$orange-gradient-hover: linear-gradient(to bottom, $orange-color-hover 0%, $orange-color-hover-second 100%);

$restart-color-base: #A9B3D4;
$restart-color-hover: #8591B9;

$to-mission-color: #D1D8EC;

// skills-colors
$imagination-color: #8C62C1;
$memory-color: #5BBF4A;
$focus-color: #E24067;
$mind-color: #4C8EDB;
$logic-color: #F39C19;

// skills-filter
$skills-filter-base: rgba(#7A92D8, .25);
$skills-filter-pressed: rgba(#7A92D8, .60);

// media queries vars
$mq-breakpoints: (
  mobile-sm: 320px,
  mobile: 320px,
  tablet: 768px,
  desktop: 1025px,
  wide: 1400px,
  desktopAd: 810px,
  mobileLandscape: 480px
);

//font
$ptsans: 'PTSans', sans-serif;

// shadows
$shadow: 0 5px 12px rgba(67, 101, 153, .11);
$inner-shadow: inset 0 5px 8px rgba(0, 28, 69, .25);

@import '~sass-mq/_mq.scss';

@mixin font-face($name, $filename, $path, $weight:normal, $style:normal) {
  @font-face {
    font-family: $name;
    src: url($path + $filename + '.woff2') format('woff2'),
      url($path + $filename + '.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin edit-cell-sudoku($borderColor, $color, $background) {
  border: 2px solid $borderColor;
  position: relative;
  color: $color;
  width: 59px;
  height: 59px;
  cursor: auto;

  &:before {
    content: '';
    position: absolute;
    background: $background;
    width: 100%;
    height: 100%;
  }
}

.location-card {
  $root: &;
  position: relative;

  &__holder {
    width: 100%;
    height: 424px;
    border-radius: 15px;
    border: 2px solid #1E4FD0;
    padding: 10px;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;

    @include mq($from: 562px) {
      padding: 30px;
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__background-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__background-img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    #{$root}_locked & {
      filter: grayscale(1);
    }
  }

  &_locked {
    box-shadow: 0px 0px 50px #FFFFFF;
    border: none;


  }

  &__mask {
    width: 100%;
    height: 55.8%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(rgba(131, 181, 255, 0), ease, #1D4ECF);
  }

  &__control {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    padding: 30px 28px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    @include mq($from: 470px) {
      flex-wrap: nowrap;
    }
  }

  &__control-wrapper {
    display: block;
    position: relative;
    text-decoration: none;

    &_locked {
      pointer-events: none;
    }
  }

  &__title {
    margin: 0;
    font-family: $ptsans;
    font-weight: 700;
    line-height: 1.33;
    font-size: 24px;
    color: $blue-text-color;

    #{$root}_locked & {
      color: #9097AE;
    }
  }

  &__missions {
    margin: 5px 0 0;
    font-family: $ptsans;
    color: $blue-text-color;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.625;
  }

  &__text {
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  &__link {
    width: 50px;

    &_mobile {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translate(10%, 10%);

      @include mq($from: 470px) {
        display: block;
        margin-top: 0;
        display: none;
      }
    }

    &_desktop {
      display: none;

      @include mq($from: 470px) {
        display: block;
        position: relative;
        flex-shrink: 0;
        margin-top: 10px;
        transform: translate(0, 0);
      }
    }
  }

  &__arrow {
    fill: white;
    width: 23px;
    height: 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__cross {
    fill: white;
    width: 23px;
    height: 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__distance {
    position: relative;

    @include mq($from: 470px) {
      margin-left: auto;
    }
  }

  &__locked-text {
    font-family: $ptsans;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.33;
    margin-top: 5px;
    color: #9097AE;
  }

  &__content {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }

  &__content-top {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    margin-bottom: 20px;

    &_reverse {
      flex-direction: column-reverse;
    }

    @include mq($from: 470px) {
      flex-direction: row;
    }
  }
}
