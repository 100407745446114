//site colors
$burger-background: #274293;
$profile-background: #1A30A5;
$profile-background-pressed: #0228AF;
$footer-background: #020E32;
$dark-blue: #274BB9;
$light-gray-blue: #D9E2F5;

//text
$blue-text-color: #274293;
$dark-blue-text-color: #041C55;

// Trainer colors
$trainer-accent-blue: #17348B;
$trainer-default-bg: #EEF3FD;
$trainer-accent-green: #23E964;

// buttons
$orange-color-base: #FF7F45;
$orange-color-accent: #FF471E;
$orange-color-hover: #FF3D0D;
$orange-color-hover-second: #FF4B1F;
$orange-color-disabled: #CED2E1;
$orange-gradient: linear-gradient(to bottom, $orange-color-base 0%, $orange-color-accent 100%);
$orange-gradient-hover: linear-gradient(to bottom, $orange-color-hover 0%, $orange-color-hover-second 100%);

$restart-color-base: #A9B3D4;
$restart-color-hover: #8591B9;

$to-mission-color: #D1D8EC;

// skills-colors
$imagination-color: #8C62C1;
$memory-color: #5BBF4A;
$focus-color: #E24067;
$mind-color: #4C8EDB;
$logic-color: #F39C19;

// skills-filter
$skills-filter-base: rgba(#7A92D8, .25);
$skills-filter-pressed: rgba(#7A92D8, .60);

// media queries vars
$mq-breakpoints: (
  mobile-sm: 320px,
  mobile: 320px,
  tablet: 768px,
  desktop: 1025px,
  wide: 1400px,
  desktopAd: 810px,
  mobileLandscape: 480px
);

//font
$ptsans: 'PTSans', sans-serif;

// shadows
$shadow: 0 5px 12px rgba(67, 101, 153, .11);
$inner-shadow: inset 0 5px 8px rgba(0, 28, 69, .25);

@import '~sass-mq/_mq.scss';

@mixin font-face($name, $filename, $path, $weight:normal, $style:normal) {
  @font-face {
    font-family: $name;
    src: url($path + $filename + '.woff2') format('woff2'),
      url($path + $filename + '.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin edit-cell-sudoku($borderColor, $color, $background) {
  border: 2px solid $borderColor;
  position: relative;
  color: $color;
  width: 59px;
  height: 59px;
  cursor: auto;

  &:before {
    content: '';
    position: absolute;
    background: $background;
    width: 100%;
    height: 100%;
  }
}

.points-got {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  $root: &;

  &__points {
    width: 47px;
    height: 47px;
    position: relative;
    text-align: center;
    margin: 20px 0 5px;

    @include mq($from: 480px) {
      width: 57px;
      margin: 0 0 20px;
    }
  }

  &__icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;

    &_2 {
      top: 4px;
      z-index: 1;
    }
  }

  &__value {
    font-family: $ptsans;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: white;
    position: absolute;
    top: 50%;
    height: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    line-height: 1;

    @include mq($from: 480px) {
      font-size: 24px;
    }
  }

  &__bar {
    background: #E8E9EC;
    width: 100%;
    height: 34px;
    box-shadow: inset 0 3px 4px rgba(123, 134, 171, 0.2);
    border-radius: 17px;
    position: relative;
    margin-bottom: 20px;

    #{$root}_gift-popup & {
      background: #030E4F;
      box-shadow: none;
    }
  }

  &__bar-fill {
    background: linear-gradient(90deg, #008CDB 0%, #17E3FF 100%);
    box-shadow: 0 5px 12px rgba(12, 190, 255, 0.5);
    border-radius: 17px 0  0 17px;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    transition: transform .5s ease-out;

    &_over90 {
      border-radius: 17px;
    }
  }

  &__bar-value {
    font-family: $ptsans;
    font-size: 16px;
    color: #041C55;
    position: relative;
    z-index: 2;
    padding-left: 20px;
    line-height: 32px;

    #{$root}_gift-popup & {
      padding-left: 0;
      padding-right: 20px;
      text-align: right;
      color: white;
      font-weight: 900;
    }
  }

  &__bar-value-dev {
    opacity: .5;
    margin-left: 4px;

    #{$root}_gift-popup & {
      opacity: 1;
    }
  }

  &__bar-value-all {
    opacity: .5;
    margin-left: 4px;

    #{$root}_gift-popup & {
      font-weight: 900;
    }
  }

  &__level {
    font-family: $ptsans;
    font-size: 18px;
    color: #041C55;
    text-align: center;

    #{$root}_gift-popup & {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 20px;
      color: white;
      font-weight: 900;
    }
  }
}
