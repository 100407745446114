//site colors
$burger-background: #274293;
$profile-background: #1A30A5;
$profile-background-pressed: #0228AF;
$footer-background: #020E32;
$dark-blue: #274BB9;
$light-gray-blue: #D9E2F5;

//text
$blue-text-color: #274293;
$dark-blue-text-color: #041C55;

// Trainer colors
$trainer-accent-blue: #17348B;
$trainer-default-bg: #EEF3FD;
$trainer-accent-green: #23E964;

// buttons
$orange-color-base: #FF7F45;
$orange-color-accent: #FF471E;
$orange-color-hover: #FF3D0D;
$orange-color-hover-second: #FF4B1F;
$orange-color-disabled: #CED2E1;
$orange-gradient: linear-gradient(to bottom, $orange-color-base 0%, $orange-color-accent 100%);
$orange-gradient-hover: linear-gradient(to bottom, $orange-color-hover 0%, $orange-color-hover-second 100%);

$restart-color-base: #A9B3D4;
$restart-color-hover: #8591B9;

$to-mission-color: #D1D8EC;

// skills-colors
$imagination-color: #8C62C1;
$memory-color: #5BBF4A;
$focus-color: #E24067;
$mind-color: #4C8EDB;
$logic-color: #F39C19;

// skills-filter
$skills-filter-base: rgba(#7A92D8, .25);
$skills-filter-pressed: rgba(#7A92D8, .60);

// media queries vars
$mq-breakpoints: (
  mobile-sm: 320px,
  mobile: 320px,
  tablet: 768px,
  desktop: 1025px,
  wide: 1400px,
  desktopAd: 810px,
  mobileLandscape: 480px
);

//font
$ptsans: 'PTSans', sans-serif;

// shadows
$shadow: 0 5px 12px rgba(67, 101, 153, .11);
$inner-shadow: inset 0 5px 8px rgba(0, 28, 69, .25);

@import '~sass-mq/_mq.scss';

@mixin font-face($name, $filename, $path, $weight:normal, $style:normal) {
  @font-face {
    font-family: $name;
    src: url($path + $filename + '.woff2') format('woff2'),
      url($path + $filename + '.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin edit-cell-sudoku($borderColor, $color, $background) {
  border: 2px solid $borderColor;
  position: relative;
  color: $color;
  width: 59px;
  height: 59px;
  cursor: auto;

  &:before {
    content: '';
    position: absolute;
    background: $background;
    width: 100%;
    height: 100%;
  }
}

.location-description {
  max-width: 450px;
  background: rgba(5, 25, 82, 0.65);
  border-radius: 10px;
  padding: 43px 40px 53px;
  box-sizing: border-box;
  padding: 30px 20px;

  @include mq($from: 470px) {
    padding: 43px 40px 53px;
  }

  &__title {
    font-family: $ptsans;
    font-weight: 700;
    font-size: 40px;
    color: white;
    line-height: .9;
    margin-top: 56px;
  }

  &__text {
    font-family: $ptsans;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.22;
    color: white;
    margin-top: 56px;
  }

  &__header {
    display: flex;
    align-items: center;justify-content: space-between;
  }

  &__arrow {
    fill: white;
    width: 15px;
    height: 15px;
    transform: scale(-1, 1);
    margin-right: 11px;
    flex-shrink: 0;
  }

  &__link {
    font-family: $ptsans;
    font-weight: 700;
    font-size: 12px;
    line-height: 1.83;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: white;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &__about {
    font-family: $ptsans;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: white;
    line-height: 1.83;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &__about-text {
    display: none;

    @include mq($from: 470px) {
      display: block;
    }
  }

  &__light {
    fill: white;
    width: 24px;
    height: 24px;
    margin-left: 13px;
    flex-shrink: 0;
  }
}
