.popupOverlay {
  background-color: rgba(22, 27, 40, 0.80);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  @include mq($from: 480px) {
    padding: 30px;
  }

  &_gift {
    padding: 0;

    @include mq($from: 480px) {
      padding: 91px 60px 174px;
    }
  }
}

.popupContent {
  box-sizing: border-box;
  width: 100%;
  max-width: 710px;
  position: relative;
  box-shadow: 0 10px 35px 0 rgba(2, 7, 33, 0.54);
  border: 0;
  z-index: 10;
  background-color: $trainer-default-bg;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include mq($from: 480px) {
    border-radius: 15px;
    height: auto;
  }

  @include mq($from: 600px) {
    padding: 40px 60px 40px 60px;
  }

  &_mission {
    background-color: $trainer-accent-blue;
    padding: 52px 30px 61px;
    max-width: 768px;
  }

  &_gift {
    background: radial-gradient(503.00px at 50.08% 33.73%, #2A45A7 0%, #0A1154 100%);
    padding: 0 0 15px;
    max-width: 649px;
    border-radius: 15px;
    box-shadow: 0px 10px 35px rgba(2, 7, 33, 0.54);

    @include mq($from: 480px) {
      padding: 0 0 61px;
    }
  }
}

.ReactModalPortal {
  position: relative;
}
