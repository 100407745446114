//site colors
$burger-background: #274293;
$profile-background: #1A30A5;
$profile-background-pressed: #0228AF;
$footer-background: #020E32;
$dark-blue: #274BB9;
$light-gray-blue: #D9E2F5;

//text
$blue-text-color: #274293;
$dark-blue-text-color: #041C55;

// Trainer colors
$trainer-accent-blue: #17348B;
$trainer-default-bg: #EEF3FD;
$trainer-accent-green: #23E964;

// buttons
$orange-color-base: #FF7F45;
$orange-color-accent: #FF471E;
$orange-color-hover: #FF3D0D;
$orange-color-hover-second: #FF4B1F;
$orange-color-disabled: #CED2E1;
$orange-gradient: linear-gradient(to bottom, $orange-color-base 0%, $orange-color-accent 100%);
$orange-gradient-hover: linear-gradient(to bottom, $orange-color-hover 0%, $orange-color-hover-second 100%);

$restart-color-base: #A9B3D4;
$restart-color-hover: #8591B9;

$to-mission-color: #D1D8EC;

// skills-colors
$imagination-color: #8C62C1;
$memory-color: #5BBF4A;
$focus-color: #E24067;
$mind-color: #4C8EDB;
$logic-color: #F39C19;

// skills-filter
$skills-filter-base: rgba(#7A92D8, .25);
$skills-filter-pressed: rgba(#7A92D8, .60);

// media queries vars
$mq-breakpoints: (
  mobile-sm: 320px,
  mobile: 320px,
  tablet: 768px,
  desktop: 1025px,
  wide: 1400px,
  desktopAd: 810px,
  mobileLandscape: 480px
);

//font
$ptsans: 'PTSans', sans-serif;

// shadows
$shadow: 0 5px 12px rgba(67, 101, 153, .11);
$inner-shadow: inset 0 5px 8px rgba(0, 28, 69, .25);

@import '~sass-mq/_mq.scss';

@mixin font-face($name, $filename, $path, $weight:normal, $style:normal) {
  @font-face {
    font-family: $name;
    src: url($path + $filename + '.woff2') format('woff2'),
      url($path + $filename + '.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin edit-cell-sudoku($borderColor, $color, $background) {
  border: 2px solid $borderColor;
  position: relative;
  color: $color;
  width: 59px;
  height: 59px;
  cursor: auto;

  &:before {
    content: '';
    position: absolute;
    background: $background;
    width: 100%;
    height: 100%;
  }
}

.tooltip {
  z-index: 10;

  &__button {
    font-weight: bold;
    font-size: 12px;
    color: #8B99C5;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    @include mq($from: mobile-sm, $until: tablet) {
      font-size: 10px;
    }
  }

  &__button-icon {
    width: 21px;
    height: 21px;

    @include mq($from: mobile-sm, $until: tablet) {
      width: 12px;
      height: 12px;
    }
  }

  &__button-text {
    margin-left: 10px;

    @include mq($from: mobile-sm, $until: tablet) {
      margin-left: 5px;
    }
  }

  &__content {
    box-sizing: border-box;
    position: absolute;
    right: 20px;
    bottom: calc(100% + 20px);
    max-width: 710px;
    background: #FFF;
    padding: 30px 65px 30px 30px;
    font-size: 16px;
    color: #041C55;
    border-radius: 10px;
    box-shadow: 0 6px 25px 0 rgba(12, 59, 130, 0.42);
    font-family: $ptsans;

    p {
      margin: 0 0 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &_mini {
      max-width: 510px;
    }

    @include mq($from: mobile-sm, $until: tablet) {
      right: 10px;
      bottom: calc(100% + 10px);
      width: calc(100vw - 20px);
      padding: 20px 45px 20px 20px;
    }

    @include mq($from: tablet, $until: desktop) {
      right: 30px;
      bottom: calc(100% + 30px);
      width: calc(100vw - 60px);
    }
  }

  &__content-close {
    position: absolute;
    top: 20px;
    right: 20px;
    display: block;
    border: 0;
    padding: 0;
    cursor: pointer;

    @include mq($from: mobile-sm, $until: tablet) {
      top: 10px;
      right: 10px;
    }
  }

  &__content-close-icon {
    width: 18px;
    height: 18px;
    fill: #8699D6;
    vertical-align: top;
  }

  &__holder {
    display: flex;
  }

  &__fox {
    display: none;

    @include mq($from: tablet) {
      display: block;
      width: 100px;
      height: 119px;
      flex-shrink: 0;
      margin-right: 20px;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
  }

  &__text {
    margin-top: 8px;
  }

  &__video {
    font-family: $ptsans;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.75;
    color: #8591B9;
    margin-top: 16px;
    text-align: left;
    cursor: pointer;
    display: flex;
  }

  &__play {
    width: 16px;
    height: 12px;
    margin-right: 15px;
  }

  &__play-icon {
    fill: #A0A6B9;
    width: 100%;
    height: 100%;
  }
}
