//site colors
$burger-background: #274293;
$profile-background: #1A30A5;
$profile-background-pressed: #0228AF;
$footer-background: #020E32;
$dark-blue: #274BB9;
$light-gray-blue: #D9E2F5;

//text
$blue-text-color: #274293;
$dark-blue-text-color: #041C55;

// Trainer colors
$trainer-accent-blue: #17348B;
$trainer-default-bg: #EEF3FD;
$trainer-accent-green: #23E964;

// buttons
$orange-color-base: #FF7F45;
$orange-color-accent: #FF471E;
$orange-color-hover: #FF3D0D;
$orange-color-hover-second: #FF4B1F;
$orange-color-disabled: #CED2E1;
$orange-gradient: linear-gradient(to bottom, $orange-color-base 0%, $orange-color-accent 100%);
$orange-gradient-hover: linear-gradient(to bottom, $orange-color-hover 0%, $orange-color-hover-second 100%);

$restart-color-base: #A9B3D4;
$restart-color-hover: #8591B9;

$to-mission-color: #D1D8EC;

// skills-colors
$imagination-color: #8C62C1;
$memory-color: #5BBF4A;
$focus-color: #E24067;
$mind-color: #4C8EDB;
$logic-color: #F39C19;

// skills-filter
$skills-filter-base: rgba(#7A92D8, .25);
$skills-filter-pressed: rgba(#7A92D8, .60);

// media queries vars
$mq-breakpoints: (
  mobile-sm: 320px,
  mobile: 320px,
  tablet: 768px,
  desktop: 1025px,
  wide: 1400px,
  desktopAd: 810px,
  mobileLandscape: 480px
);

//font
$ptsans: 'PTSans', sans-serif;

// shadows
$shadow: 0 5px 12px rgba(67, 101, 153, .11);
$inner-shadow: inset 0 5px 8px rgba(0, 28, 69, .25);

@import '~sass-mq/_mq.scss';

@mixin font-face($name, $filename, $path, $weight:normal, $style:normal) {
  @font-face {
    font-family: $name;
    src: url($path + $filename + '.woff2') format('woff2'),
      url($path + $filename + '.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin edit-cell-sudoku($borderColor, $color, $background) {
  border: 2px solid $borderColor;
  position: relative;
  color: $color;
  width: 59px;
  height: 59px;
  cursor: auto;

  &:before {
    content: '';
    position: absolute;
    background: $background;
    width: 100%;
    height: 100%;
  }
}

.menu {
  background-color: $burger-background;
  padding: 25px 0 20px;
  position: relative;
  min-height: 100vh;
  box-sizing: border-box;

  &__main {
    text-align: center;
  }

  &__close {
    height: 17px;
    width: 17px;
    display: block;
    position: absolute;
    right: 24px;
    top: 30px;
  }

  &__close-icon {
    fill: #9CA6C6;
    height: 100%;
    width: 100%;
  }

  &__navigation {
    margin: 35px 0;
  }

  &__projects {
    padding-top: 35px;
    border-top: 1px solid rgba(0, 0, 0, 0.23);
    max-width: 420px;
    margin: 0 auto;
  }

  &__projects-header {
    font-family: $ptsans;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 3px;
    color: rgba(223, 223, 223, 0.48);
  }

  &__projects-box {
    margin-top: 30px;
  }

  &__socials {
    margin-top: 112px;
  }

  &__contacts {
    margin-top: 40px;
  }

  &__created-by {
    margin-top: 38px;
  }

  &__logo {
    width: 95px;
    height: 43px;
    margin: 0 auto;

    @include mq($from: tablet) {
      width: 115px;
      height: 52px;
    }
  }
}
