//site colors
$burger-background: #274293;
$profile-background: #1A30A5;
$profile-background-pressed: #0228AF;
$footer-background: #020E32;
$dark-blue: #274BB9;
$light-gray-blue: #D9E2F5;

//text
$blue-text-color: #274293;
$dark-blue-text-color: #041C55;

// Trainer colors
$trainer-accent-blue: #17348B;
$trainer-default-bg: #EEF3FD;
$trainer-accent-green: #23E964;

// buttons
$orange-color-base: #FF7F45;
$orange-color-accent: #FF471E;
$orange-color-hover: #FF3D0D;
$orange-color-hover-second: #FF4B1F;
$orange-color-disabled: #CED2E1;
$orange-gradient: linear-gradient(to bottom, $orange-color-base 0%, $orange-color-accent 100%);
$orange-gradient-hover: linear-gradient(to bottom, $orange-color-hover 0%, $orange-color-hover-second 100%);

$restart-color-base: #A9B3D4;
$restart-color-hover: #8591B9;

$to-mission-color: #D1D8EC;

// skills-colors
$imagination-color: #8C62C1;
$memory-color: #5BBF4A;
$focus-color: #E24067;
$mind-color: #4C8EDB;
$logic-color: #F39C19;

// skills-filter
$skills-filter-base: rgba(#7A92D8, .25);
$skills-filter-pressed: rgba(#7A92D8, .60);

// media queries vars
$mq-breakpoints: (
  mobile-sm: 320px,
  mobile: 320px,
  tablet: 768px,
  desktop: 1025px,
  wide: 1400px,
  desktopAd: 810px,
  mobileLandscape: 480px
);

//font
$ptsans: 'PTSans', sans-serif;

// shadows
$shadow: 0 5px 12px rgba(67, 101, 153, .11);
$inner-shadow: inset 0 5px 8px rgba(0, 28, 69, .25);

@import '~sass-mq/_mq.scss';

@mixin font-face($name, $filename, $path, $weight:normal, $style:normal) {
  @font-face {
    font-family: $name;
    src: url($path + $filename + '.woff2') format('woff2'),
      url($path + $filename + '.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin edit-cell-sudoku($borderColor, $color, $background) {
  border: 2px solid $borderColor;
  position: relative;
  color: $color;
  width: 59px;
  height: 59px;
  cursor: auto;

  &:before {
    content: '';
    position: absolute;
    background: $background;
    width: 100%;
    height: 100%;
  }
}

.profile-gifts {
  width: 100%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  $root: &;

  &__holder {
    background: rgba(#001D68, .7);
    border-radius: 10px 10px 0 0;
    height: 100%;
    box-sizing: border-box;
    border-bottom: 4px solid #1339FF;
    box-shadow: inset  0  -15px 20px -10px rgba(25, 48, 251, 0.78);
    padding: 28px 20px 120px;

    @include mq($from: 686px) {
      padding: 35px 54px 120px;
    }
  }

  &__form {
    width: 7px;
    height: 180px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: none;

    @include mq($from: 686px) {
      display: block;
    }

    &_2 {
      right: 0;
      transform: translateY(-50%) rotate(180deg);
    }
  }

  &__form-icon {
    width: 100%;
    height: 100%;
    fill: #19378E;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;

    @include mq($from: 520px) {
      flex-direction: row;
    }
  }

  &__title {
    font-family: $ptsans;
    font-weight: 700;
    font-size: 24px;
    line-height: 1.3;
    color: #EEF3FD;
  }

  &__to-map {
    display: block;
    font-family: $ptsans;
    font-weight: 700;
    font-size: 12px;
    line-height: 35px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: white;
    border-radius: 50px;
    height: 35px;
    background-color: rgba(255, 255, 255, 0.15);
    width: 117px;
    text-align: center;
    text-decoration: none;
    margin-top: 20px;

    @include mq($from: 520px) {
      margin-top: 0;
    }
  }

  &__desc {
    margin-top: 29px;
    font-family: $ptsans;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.44;
    color: white;

    @include mq($from: 686px) {
      margin-top: 36px;
    }
  }

  &__list-holder {
    margin: 0 auto;
    width: fit-content;

    @include mq($from: 340px) {
      justify-content: flex-start;
      max-width: 245px;
    }

    @include mq($from: 429px) {
      max-width: 157px;
    }

    @include mq($from: 440px) {
      max-width: 347px;
    }

    @include mq($from: 626px) {
      max-width: 531px;
    }

    @include mq($from: 686px) {
      max-width: 410px;
    }

    @include mq($from: 793px) {
      max-width: 630px;
    }

    @include mq($from: 980px) {
      max-width: 771px;
    }

    @include mq($from: 1200px) {
      max-width: 1038px;
    }
  }

  &__list {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -15px;

    @include mq($from: 340px) {
      justify-content: flex-start;
    }
  }

  &__item {
    width: 190px;
    height: 185px;
    box-sizing: border-box;
    margin: 30px auto 45px;

    @include mq($from: 340px) {
      width: 136px;
      height: 113px;
      padding: 0 10px;
      margin: 30px 0 45px;
    }

    @include mq($from: 429px) {
      width: 187px;
      height: 153px;
      padding: 0 15px;
    }

    @include mq($from: 686px) {
      width: 220px;
      height: 185px;
    }

    @include mq($from: 980px) {
      width: 267px;
      height: 222px;
    }
  }

  &__more {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #1339FF;
    box-shadow: 0px -4px 15px rgba(25, 48, 251, 0.78);
    width: 259px;
    height: 40px;
    border-radius: 10px 10px 0 0;
    font-family: $ptsans;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.75;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    @include mq($from: 686px) {
      width: auto;
    }
  }

  &__more-icon {
    width: 7px;
    height: 7px;
    border: 2px solid white;
    border-left: 0;
    border-top: 0;
    transform: translateY(-20%) rotate(45deg);
    margin-left: 15px;
    transition: transform .3s ease-in-out;

    #{$root}_opened & {
      transform: translateY(40%) rotate(225deg);
    }
  }
}
