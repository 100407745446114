//site colors
$burger-background: #274293;
$profile-background: #1A30A5;
$profile-background-pressed: #0228AF;
$footer-background: #020E32;
$dark-blue: #274BB9;
$light-gray-blue: #D9E2F5;

//text
$blue-text-color: #274293;
$dark-blue-text-color: #041C55;

// Trainer colors
$trainer-accent-blue: #17348B;
$trainer-default-bg: #EEF3FD;
$trainer-accent-green: #23E964;

// buttons
$orange-color-base: #FF7F45;
$orange-color-accent: #FF471E;
$orange-color-hover: #FF3D0D;
$orange-color-hover-second: #FF4B1F;
$orange-color-disabled: #CED2E1;
$orange-gradient: linear-gradient(to bottom, $orange-color-base 0%, $orange-color-accent 100%);
$orange-gradient-hover: linear-gradient(to bottom, $orange-color-hover 0%, $orange-color-hover-second 100%);

$restart-color-base: #A9B3D4;
$restart-color-hover: #8591B9;

$to-mission-color: #D1D8EC;

// skills-colors
$imagination-color: #8C62C1;
$memory-color: #5BBF4A;
$focus-color: #E24067;
$mind-color: #4C8EDB;
$logic-color: #F39C19;

// skills-filter
$skills-filter-base: rgba(#7A92D8, .25);
$skills-filter-pressed: rgba(#7A92D8, .60);

// media queries vars
$mq-breakpoints: (
  mobile-sm: 320px,
  mobile: 320px,
  tablet: 768px,
  desktop: 1025px,
  wide: 1400px,
  desktopAd: 810px,
  mobileLandscape: 480px
);

//font
$ptsans: 'PTSans', sans-serif;

// shadows
$shadow: 0 5px 12px rgba(67, 101, 153, .11);
$inner-shadow: inset 0 5px 8px rgba(0, 28, 69, .25);

@import '~sass-mq/_mq.scss';

@mixin font-face($name, $filename, $path, $weight:normal, $style:normal) {
  @font-face {
    font-family: $name;
    src: url($path + $filename + '.woff2') format('woff2'),
      url($path + $filename + '.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin edit-cell-sudoku($borderColor, $color, $background) {
  border: 2px solid $borderColor;
  position: relative;
  color: $color;
  width: 59px;
  height: 59px;
  cursor: auto;

  &:before {
    content: '';
    position: absolute;
    background: $background;
    width: 100%;
    height: 100%;
  }
}

.mission-card {
  $root: &;

  background: $trainer-default-bg;
  box-shadow: 0px 20px 30px rgba(17, 13, 40, 0.25);
  border-radius: 15px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  min-height: 292px;

  &_locked {
    padding-top: 250px;

    @include mq($from: 622px) {
      padding-top: 0;
    }
  }

  @include mq($from: 622px) {
    overflow: hidden;
  }

  &__holder {
    width: 100%;
    overflow: hidden;
    padding: 35px 35px 40px 25px;
    position: relative;
    padding-top: 300px;

    @include mq($from: 622px) {
      padding: 35px 30px 30px;
    }

    #{$root}_completed & {
      border: 5px solid #07C5A3;
      border-radius: 15px;
      box-sizing: border-box;

      @include mq($from: 622px) {
        padding: 35px 30px 25px;
      }
    }

    #{$root}_locked & {
      padding: 35px 30px 33px;
    }
  }

  &_locked {
    background: rgba(2, 14, 50, 0.6);
  }

  &__play-icon {
    width: 22px;
    height: 21px;
    fill: white;
    margin-left: 25px;
    vertical-align: middle;
  }

  &__play {
    margin: 40px auto 0;
    max-width: 420px;

    @include mq($from: 622px) {
      max-width: 217px;
      position: absolute;
      bottom: 0;
      width: 217px;
    }
  }

  &__name {
    font-family: $ptsans;
    font-weight: 700;
    font-size: 28px;
    color: #041C55;
    line-height: 1.16;
    padding-right: 15px;
    margin: 0;

    #{$root}_locked & {
      color: #9097AE;
    }
  }

  &__mission-word {
    display: none;

    @include mq($from: 820px) {
      display: inline;
    }
  }

  &__about {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 303px;
    right: 20px;
    cursor: pointer;
    z-index: 1;

    #{$root}_locked & {
      display: none;
    }

    @include mq($from: 622px) {
      top: 20px;
    }
  }

  &__light-icon {
    width: 100%;
    height: 100%;
    fill: #A9B3D5;
  }

  &__story {
    font-family: $ptsans;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.44;
    color: black;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: white;
    z-index: 10;
    border-radius: 15px;
    padding: 65px 30px 53px;
    box-sizing: border-box;
  }

  &__close {
    width: 24px;
    height: 24px;
    background: #C4C4C4;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  &__close-icon {
    width: 12px;
    height: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__slider {
    height: 295px;
    position: absolute;
    z-index: 2;
    left: 50%;
    top: -44px;
    transform: translateX(-50%);
    width: 100%;
    padding: 0 35px 0 25px;
    box-sizing: border-box;

    @include mq($from: 622px) {
      position: absolute;
      left: 25px;
      width: 42%;
      transform: none;
      padding: 0;
    }

    @include mq($from: desktop) {
      left: 50px;
    }
  }

  &__info {
    position: relative;
    height: 100%;
    box-sizing: border-box;

    @include mq($from: 622px) {
      margin-left: 52%;
      padding: 10px;
    }
  }

  &__result {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    border-radius: 15px;

    @include mq($from: 622px) {
      overflow: hidden;
      left: 20px;
      width: calc(100% - 20px);
    }
  }

  &__result-btn {
    width: 44px;
    height: 78px;
    background: #07C5A3;
    border-radius: 15px 0 0 15px;
    position: absolute;
    right: -5px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 3;
    display: none;

    &_close {
      transform: translateY(-50%) rotate(180deg);
      left: 0;
    }

    @include mq($from: 622px) {
      display: block;
    }
  }

  &__result-arrow {
    width: 10px;
    height: 10px;
    background: none;
    border: solid white;
    border-right-width: 0;
    border-top-width: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-25%, -50%) rotate(45deg);
  }

  &__result-btn-mobile {
    display: block;
    margin-top: 15px;

    @include mq($from: 622px) {
      display: none;
    }
  }
}
