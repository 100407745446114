//site colors
$burger-background: #274293;
$profile-background: #1A30A5;
$profile-background-pressed: #0228AF;
$footer-background: #020E32;
$dark-blue: #274BB9;
$light-gray-blue: #D9E2F5;

//text
$blue-text-color: #274293;
$dark-blue-text-color: #041C55;

// Trainer colors
$trainer-accent-blue: #17348B;
$trainer-default-bg: #EEF3FD;
$trainer-accent-green: #23E964;

// buttons
$orange-color-base: #FF7F45;
$orange-color-accent: #FF471E;
$orange-color-hover: #FF3D0D;
$orange-color-hover-second: #FF4B1F;
$orange-color-disabled: #CED2E1;
$orange-gradient: linear-gradient(to bottom, $orange-color-base 0%, $orange-color-accent 100%);
$orange-gradient-hover: linear-gradient(to bottom, $orange-color-hover 0%, $orange-color-hover-second 100%);

$restart-color-base: #A9B3D4;
$restart-color-hover: #8591B9;

$to-mission-color: #D1D8EC;

// skills-colors
$imagination-color: #8C62C1;
$memory-color: #5BBF4A;
$focus-color: #E24067;
$mind-color: #4C8EDB;
$logic-color: #F39C19;

// skills-filter
$skills-filter-base: rgba(#7A92D8, .25);
$skills-filter-pressed: rgba(#7A92D8, .60);

// media queries vars
$mq-breakpoints: (
  mobile-sm: 320px,
  mobile: 320px,
  tablet: 768px,
  desktop: 1025px,
  wide: 1400px,
  desktopAd: 810px,
  mobileLandscape: 480px
);

//font
$ptsans: 'PTSans', sans-serif;

// shadows
$shadow: 0 5px 12px rgba(67, 101, 153, .11);
$inner-shadow: inset 0 5px 8px rgba(0, 28, 69, .25);

@import '~sass-mq/_mq.scss';

@mixin font-face($name, $filename, $path, $weight:normal, $style:normal) {
  @font-face {
    font-family: $name;
    src: url($path + $filename + '.woff2') format('woff2'),
      url($path + $filename + '.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin edit-cell-sudoku($borderColor, $color, $background) {
  border: 2px solid $borderColor;
  position: relative;
  color: $color;
  width: 59px;
  height: 59px;
  cursor: auto;

  &:before {
    content: '';
    position: absolute;
    background: $background;
    width: 100%;
    height: 100%;
  }
}

.train-skills {
  padding: 40px 30px 56px;
  background: linear-gradient(179.69deg, $trainer-accent-blue 0.34%, #23429C 95.53%);
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mq($from: tablet) {
    padding: 60px 20px 56px;
  }

  &__title {
    font-family: $ptsans;
    font-weight: 700;
    font-size: 34px;
    line-height: 1.11;
    color: white;
    margin: 0;
    text-align: center;
  }

  &__text {
    font-family: $ptsans;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.44;
    color: white;
    margin: 20px auto 0;
    max-width: 372px;
    text-align: center;
  }

  &__list {
    max-width: 1211px;
    margin: 50px 0 0 -15px;
    width: 100%;
    display: flex;
    flex-direction: column;

    @include mq($from: tablet) {
      flex-direction: row;
      justify-content: center;
    }

    @include mq($from: desktopAd) {
      margin-left: -30px;
    }
  }

  &__item {
    width: 335px;
    height: 241px;
    max-width: 100%;
    border-radius: 10px;

    &:hover {
      z-index: 2;
    }

    & + & {
      margin-top: 30px;

      @include mq($from: tablet) {
        margin-top: 0;
        margin-left: 30px;
      }
    }

    &:last-child {
      display: block;

      @include mq($from: tablet) {
        display: none;
      }

      @include mq($from: desktop) {
        display: block;
      }
    }
  }

  &__link {
    width: 274px;
    margin: 50px auto 0;
  }

  &__item-card {
    padding-left: 15px;
    margin-bottom: 30px;
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 100%;
    height: 241px;
    opacity: 0;

    &_mobile {
      height: 292px;
    }

    @include mq($from: 620px) {
      flex: 1 1 50%;
      max-width: 50%;
    }

    @include mq($from: desktopAd) {
      padding-left: 30px;
    }

    @include mq($from: desktop) {
      flex: 1 1 33%;
      max-width: 33.3%;
    }

    @for $i from 1 through 9 {
      &:nth-child(#{$i}) {
        animation: fadeOut 0.6s ease-out;
        animation-delay: #{($i - 1) * 0.2}s;
        animation-fill-mode: forwards;
      }
    }

    @for $i from 10 through 12 {
      &:nth-child(3n + #{$i}) {
        animation: fadeOut .6s ease-out;
        animation-fill-mode: forwards;
        animation-delay: #{0.2 * ($i - 10)}s;
      }
    }
  }
}
