//site colors
$burger-background: #274293;
$profile-background: #1A30A5;
$profile-background-pressed: #0228AF;
$footer-background: #020E32;
$dark-blue: #274BB9;
$light-gray-blue: #D9E2F5;

//text
$blue-text-color: #274293;
$dark-blue-text-color: #041C55;

// Trainer colors
$trainer-accent-blue: #17348B;
$trainer-default-bg: #EEF3FD;
$trainer-accent-green: #23E964;

// buttons
$orange-color-base: #FF7F45;
$orange-color-accent: #FF471E;
$orange-color-hover: #FF3D0D;
$orange-color-hover-second: #FF4B1F;
$orange-color-disabled: #CED2E1;
$orange-gradient: linear-gradient(to bottom, $orange-color-base 0%, $orange-color-accent 100%);
$orange-gradient-hover: linear-gradient(to bottom, $orange-color-hover 0%, $orange-color-hover-second 100%);

$restart-color-base: #A9B3D4;
$restart-color-hover: #8591B9;

$to-mission-color: #D1D8EC;

// skills-colors
$imagination-color: #8C62C1;
$memory-color: #5BBF4A;
$focus-color: #E24067;
$mind-color: #4C8EDB;
$logic-color: #F39C19;

// skills-filter
$skills-filter-base: rgba(#7A92D8, .25);
$skills-filter-pressed: rgba(#7A92D8, .60);

// media queries vars
$mq-breakpoints: (
  mobile-sm: 320px,
  mobile: 320px,
  tablet: 768px,
  desktop: 1025px,
  wide: 1400px,
  desktopAd: 810px,
  mobileLandscape: 480px
);

//font
$ptsans: 'PTSans', sans-serif;

// shadows
$shadow: 0 5px 12px rgba(67, 101, 153, .11);
$inner-shadow: inset 0 5px 8px rgba(0, 28, 69, .25);

@import '~sass-mq/_mq.scss';

@mixin font-face($name, $filename, $path, $weight:normal, $style:normal) {
  @font-face {
    font-family: $name;
    src: url($path + $filename + '.woff2') format('woff2'),
      url($path + $filename + '.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin edit-cell-sudoku($borderColor, $color, $background) {
  border: 2px solid $borderColor;
  position: relative;
  color: $color;
  width: 59px;
  height: 59px;
  cursor: auto;

  &:before {
    content: '';
    position: absolute;
    background: $background;
    width: 100%;
    height: 100%;
  }
}

.timeline {
  height: 100%;
  position: relative;

  &__pane {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }

  &__line {
    height: 100%;
    transition: width 1s linear, background-image 0.4s ease-in-out, box-shadow 0.4s ease-in-out;

    &_no-transition {
      transition: none;
    }

    &_green {
      background-image: linear-gradient(to right, rgba(23,177,94,1) 0%,rgba(176,220,0,1) 100%);
    }

    &_orange {
      background-image: linear-gradient(to right, rgba(255,93,23,1) 0%,rgba(249,184,17,1) 100%);
    }
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    font-size: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include mq($until: 500px) {
      width: 100%;

      &_mobile {
        font-size: 18px;
        line-height: 12px;
        top: 11px;
        transform: translate(-50%, 0);
      }
    }
  }

  &__label-text {
    font-size: 26px;
    line-height: .46;
    color: white;
    font-family: $ptsans;
    font-style: normal;
    font-weight: 700;
    display: flex;
    align-items: center;
    text-align: right;

    @include mq($until: 500px) {
      margin: auto;
    }
  }

  &__label-timer {
    font-family: $ptsans;
    font-size: 25px;
    font-weight: 700;
    display: inline-flex;
    align-items: center;
    color: white;
    margin-left: 16px;
    line-height: .9;
  }

  &__clock {
    width: 24px;
    height: 25px;
    margin-right: 16px;
  }

  &__stages {
    font-family: $ptsans;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: .9;
    text-align: right;
    color: rgba(white, .5);
    position: absolute;
    top: 50%;
    right: 31px;
    transform: translateY(-50%);

    @include mq($until: 500px) {
      &_mobile {
        font-size: 14px;
        line-height: 12px;
        left: 50%;
        top: auto;
        bottom: 6px;
        right: auto;
        transform: translate(-50%, 0);
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .timeline {
    &__label-text {
      font-size: 20px;
      line-height: 1;
    }

    &__label-timer {
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 500px) {
  .timeline {
    &__clock {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
  }
}
