body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: 320px;
  color: $dark-blue-text-color;
}

button {
  background: none;
  border: none;
  padding: 0;

  &:focus {
    outline: 0;
  }
}

.orange-button {
  text-align: center;
  font-family: $ptsans;
  font-weight: 700;
  font-size: 14px;
  line-height: 50px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #FFFFFF;
  text-decoration: none;
  background: linear-gradient(180deg, $orange-color-base 0%, $orange-color-accent 100%);
  box-shadow: 0 8px 20px rgba(238, 74, 0, 0.5);
  border-radius: 29px;
  height: 50px;
  display: block;
  margin-top: 25px;
  outline: 0;

  &:hover,
  &:focus {
    background: linear-gradient(180deg, $orange-color-hover 0%, $orange-color-accent 100%);
  }

  &:disabled {
    background: linear-gradient(180deg, $orange-color-disabled 0%, $orange-color-accent 100%);
  }
}

ul {
  list-style-type: none;
  padding: 0;
}

.overflow-hidden {
  overflow: hidden;
}
