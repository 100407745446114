//slide-down
.slide-down-enter {
  transform: translateY(-100%);

  &.slide-down-enter-active {
    transform: translateY(0) ;
    transition: transform .4s ease-out;
  }
}

.slide-down-leave {
  transform: translateY(0) ;

  &.slide-down-leave-active {
    transform: translateY(-100%);
    transition: transform .4s ease-out;
  }
}

//slide-left
.slide-left-enter {
  transform: translateX(100%);

  &.slide-left-enter-active {
    transform: translateX(0) ;
    transition: transform .4s ease-out;
  }
}

.slide-left-leave {
  transform: translateX(0) ;

  &.slide-left-leave-active {
    transform: translateX(100%);
    transition: transform .4s ease-out;
  }
}

//scale
.scale-enter {
  transform: scale(0);

  &.scale-enter-active {
    transform: scale(1);
    transition: transform .3s ease-out;
  }
}

.scale-leave {
  transform: scale(1) ;

  &.scale-leave-active {
    transform: scale(0);
    transition: transform .3s ease-out;
  }
}
